import React from "react";

export default function shirtShort(props: { color: string }): SVGElement {
  const { color } = props;
  return (
    <svg
      style={{
        position: "absolute",
        bottom: "-2.5%",
        width: "100%",
        height: "26%"
      }}
      width="281"
      height="93"
      viewBox="0 0 281 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M276.366 90.8628H3.45626C3.49074 90.7926 3.52546 90.7219 3.5604 90.6507C8.10518 81.395 16.4899 64.319 27.4025 49.0413C33.5961 40.3702 40.5303 32.3872 47.9416 26.7719C55.3538 21.1558 63.0614 18.0405 70.8916 18.7256C85.946 20.0429 99.3549 28.2854 112.817 36.5604C114.092 37.3444 115.368 38.1288 116.646 38.9076C131.131 47.7349 146.005 55.9312 162.365 52.3445C167.899 51.1311 171.629 48.5345 173.976 45.1797C176.29 41.8727 177.122 38.0292 177.266 34.5166C177.41 30.9989 176.871 27.6738 176.311 25.2562C176.049 24.1257 175.779 23.1785 175.564 22.4839C179.189 18.8351 183.027 16.71 186.989 15.7533C191.284 14.7164 195.876 15.0158 200.707 16.4448C210.437 19.3229 220.847 26.6969 231.007 36.1789C249.621 53.5516 266.7 77.3231 276.366 90.8628Z" fill={color} stroke="black" strokeWidth="4.27431" />
    </svg>
  );
}
