import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allDrafts: [],
    allPending: [],
    allPublished: [],
    blog: {},
    blogCategories: [],
    blogTopics: [],
    loading: false,
};

const blogSlice = createSlice({
    name: "blog",
    initialState: initialState,
    reducers: {
        setAllDrafts(state, value) {
            state.allDrafts = value.payload;
        },
        setAllPending(state, value) {
            state.allPending = value.payload;
        },
        setAllPublished(state, value) {
            state.allPublished = value.payload;
        },
        setBlogCategories(state, value) {
            state.blogCategories = value.payload;
        },
        setBlogTopics(state, value) {
            state.blogTopics = value.payload;
        },

        setBlog(state, value) {
            state.blog = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
    },
});

export const { setBlogTopics, setAllDrafts, setLoading, setAllPending, setAllPublished, setBlog, setBlogCategories } = blogSlice.actions;

export default blogSlice.reducer;
