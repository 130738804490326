import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSuccessStories } from '../../services/operations/successStories';

const SuccessStories = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { allSuccessStories } = useSelector((state) => state.successStories)
    const { signinData } = useSelector((state) => (state.auth))

    useEffect(() => {
        if (signinData?.role !== 'Admin') {
            navigate('/admin')
        }

        if (signinData?.role === 'Admin') {
            dispatch(getSuccessStories())
        }
    }, [navigate, signinData?.role, dispatch])
    // const success = []

    // console.log(allSuccessStories)

//     company_name
// : 
// "SDE @ tuf"
// id
// : 
// 33
// image_link
// : 
// "https://www.linkedin.com/in/siddhant-meshram-36794a258/"
// linkedIn_link
// : 
// "https://www.linkedin.com/in/siddhant-meshram-36794a258/"
// name
// : 
// "Siddhant Meshram"
// story_text
// : 
// "https://www.linkedin.com/in/siddhant-meshram-36794a258/"

    return (

        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mb-8">
                            <div className="flex-grow font-primary">
                                <section className=''>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row justify-between items-center">

                                            <div className="relative">
                                            </div>
                                            <Link to="/success-stories/add" className="px-6 py-2 rounded-lg text-white bg-brand hover:bg-brand_50 w-fit ">Add Success Story</Link>
                                        </div>
                                        <div className="mt-6">
                                            {allSuccessStories?.length > 0 ? (
                                                <Table showDelete={true} page="success" topics={allSuccessStories} />
                                            ) : (
                                                <div className="flex justify-center items-center">
                                                    <h1 className="text-xl font-medium dark:text-zinc-200">There are no success stories.</h1>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default SuccessStories