import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allSuccessStories: [],
    loading: false,
};

const successStoriesSlice = createSlice({
    name: "successStories",
    initialState: initialState,
    reducers: {
        getAllSuccessStoriess(state, value) {
            state.allSuccessStories = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
    },
});

export const { getAllSuccessStoriess } = successStoriesSlice.actions;

export default successStoriesSlice.reducer;
