import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBugs } from '../../services/operations/bugs';
import BugsTable from '../../components/Shared/Tables/BugsTable/BugsTable';
import { sortByNotClosedBugs } from '../../utils/Utils';

const Bugs = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { allBugs } = useSelector((state) => state.bug)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllBugs())
    }, [dispatch])

    const sortedBugs  = sortByNotClosedBugs(allBugs)
    // console.log(sortedBugs)
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {sortedBugs?.length > 0 ? (
                            <BugsTable topics={sortedBugs} />
                        ) : (
                            <div className="flex justify-center items-center">
                                <h1 className="text-xl font-medium dark:text-zinc-200">There are no bugs.</h1>
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Bugs