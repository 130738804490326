import React, { useState } from 'react';
import TablePagination from "./TablePagination"
import TableItem from './TableItem';

function Table({ topics, showDelete, page }) {


    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = topics?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="  font-primary  relative">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>
                                {page === 'success' ? (
                                    <>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                            <div className="font-semibold flex items-start">User Name</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Story</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">LinkedIn Link</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Image Link</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Company Name</div>
                                        </th>
                                    </>
                                ) : (
                                    <>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                            <div className="font-semibold flex items-start">Name</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Date</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action</div>
                                        </th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        {
                            currentItems?.map((data, index) => {
                                return (
                                    <TableItem
                                        showDelete={showDelete}
                                        id={data?.id}
                                        num={index + 1}
                                        page={page}
                                        name={data?.problem_name}
                                        userName={data?.name}
                                        story_text={data?.story_text}
                                        linkedIn_link={data?.linkedIn_link}
                                        image_link={data?.image_link}
                                        company_name={data?.company_name}
                                        date={data?.created_at}
                                    />
                                )
                            })
                        }
                    </table>

                </div>
            </div>
            <div className="dark:bg-dark_50  border-2 border-t-0 border-lead100 dark:border-lead500">
                <TablePagination
                    itemsPerPage={itemsPerPage}
                    totalItems={topics?.length}
                    currentPage={currentPage}
                    paginate={paginate}
                    indexOfFirstItem={indexOfFirstItem}
                    indexOfLastItem={indexOfLastItem}
                />
            </div>
        </div>
    );
}

export default Table;
