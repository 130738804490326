import React, { useState } from 'react'
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';

const PreviewCourse = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (

        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                       <span>PreviewCourse</span>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default PreviewCourse