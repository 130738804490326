import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allBugs: [],
    loading: false,
};

const bugSlice = createSlice({
    name: "bug",
    initialState: initialState,
    reducers: {
        setAllBugs(state, value) {
            state.allBugs = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
    },
});

export const { setAllBugs, setLoading } = bugSlice.actions;

export default bugSlice.reducer;
