import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addFeatureFlag, editFeatureFlag, getAllFeatureFlags, removeFeatureFlag } from '../../services/operations/auth';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../components/Modal/Modal';

const AddFeatureFlag = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const { allFeatureFlags } = useSelector((state) => state.auth)
    const [featureId, setFeatureId] = useState(null);

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        mode: 'all',
        defaultValues: {
            content: null,
            isVisible: 0,
        },
    });
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllFeatureFlags())
    }, [dispatch])
    const handleAddFeatureFlag = (e) => {
        e.stopPropagation()
        setModalOpen(true);
        setIsEdit(false)
        setValue('feature', '');
        setValue('isVisible', 0);
        setValue('content', '');
    }
    const onSubmit = (data) => {
        if(isEdit) {
            const { feature, isVisible, content } = data
            dispatch(editFeatureFlag(feature, isVisible, content, featureId, setModalOpen))
        }else {
            const { feature, isVisible, content } = data
            dispatch(addFeatureFlag(feature, isVisible, content, setModalOpen))
        }
    };

    const handleDelete = (e, id) => {
        dispatch(removeFeatureFlag(id))
    };
    const handleEdit = (e, feature, isVisible, content, id) => {
        setIsEdit(true);
        e.stopPropagation();
        setModalOpen(true);
        setFeatureId(id)
        setValue('feature', feature);
        setValue('isVisible', isVisible);
        setValue('content', content);
    };
    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="flex flex-row justify-between items-center">

                            <div className="relative">
                            </div>
                            <button onClick={(e) => handleAddFeatureFlag(e)} className="px-6 py-2 rounded-lg text-white bg-brand hover:bg-brand_50 w-fit ">Add Feature Flag</button>
                        </div>
                        <div className="font-primary  relative mt-10">
                            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                                <div className="overflow-x-auto">
                                    <table className="table-auto w-full  divide-y  divide-gray-200">
                                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                                            <tr>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">No.</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/3 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                                    <div className="font-semibold flex items-start">Feature</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">isVisible</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">Content</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">Action</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        {allFeatureFlags && allFeatureFlags?.length > 0 &&
                                            allFeatureFlags?.map((data, index) => {
                                                return (
                                                    <tbody className="text-[13px]">
                                                        <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                                                            <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                                    {data.id}.
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.feature}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.isVisible}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.content}
                                                                </div>
                                                            </td>

                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                                                    <button onClick={(e) => handleDelete(e, data.id)} className='btn '>Delete</button>
                                                                </div>
                                                                <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                                                    <button onClick={(e) => handleEdit(e, data?.feature, data?.isVisible, data?.content,data?.id)} className='btn  mt-2'>Edit</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Modal title={"Add Banner"} modalOpen={modalOpen} setModalOpen={setModalOpen}>
                            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-4 mt-4">
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Feature<span className="text-red-500">*</span>
                                        </label>
                                        {errors?.feature && (
                                            <span className="text-red-500">{errors.feature.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="feature"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className="form-input w-full"
                                                placeholder="Enter feature"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                        Content
                                        </label>
                                        {errors?.content && (
                                            <span className="text-red-500">{errors.content.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="content"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className="form-input w-full"
                                                placeholder="Enter content if any"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Is Visible
                                        </label>
                                        {errors?.isVisible && (
                                            <span className="text-red-500">{errors.isVisible.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="isVisible"
                                        control={control}
                                        defaultValue={0}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className="form-select w-full"
                                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                            >
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>
                                            </select>
                                        )}
                                    />
                                </div>

                                {/* Submit Button */}
                                <div className="mt-4">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddFeatureFlag