import toast from "react-hot-toast"
import { getAllSuccessStoriess } from "../../slices/successStoriesSlice"
import { successStoriesEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import { isArrayEmpty } from "../../utils/Utils"

const { GET_ALL_STORIES } = successStoriesEndpoints

export function getSuccessStories() {
    return async (dispatch, getState) => {
        try {
            if (isArrayEmpty(getState().successStories.allSuccessStories)) {
                const response = await apiConnector("GET", GET_ALL_STORIES)
                dispatch(getAllSuccessStoriess(response.data.result))
            }

        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}