/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import RichEditor from '../../../components/RichEditor/RichEditor';

const AddCourse = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        defaultValues: '',
    });

    const numberOfDivisions = watch('divisions');

    const onEditorContentChanged = ({ name, html }) => {
        setValue(name, html);
    };

    const handleOnSubmit = (data) => {
        console.log(data);
    }

    return (

        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className=" mb-8">
                            <div className="flex-grow font-primary">
                                <section className='p-4'>
                                    <form onSubmit={handleSubmit(handleOnSubmit)} className="flex flex-col mt-6 gap-y-6">
                                        <div className="flex flex-col gap-y-2">
                                            <div className='flex items-center gap-x-4'>
                                                <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Problem ID<span className="text-red-500">*</span></span>

                                            </div>
                                            <Controller
                                                name="problem_id"
                                                control={control}
                                                defaultValue=''
                                                render={({ field, fieldState }) => (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="problem_name"
                                                            className="form-input w-full text-gray-300"
                                                            placeholder="Problem Name"
                                                            {...field}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-y-2">
                                            <div className='flex items-center gap-x-4'>
                                                <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Problem Name<span className="text-red-500">*</span></span>

                                            </div>
                                            <Controller
                                                name="problem_name"
                                                control={control}
                                                defaultValue=''
                                                render={({ field, fieldState }) => (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="problem_name"
                                                            className="form-input w-full text-gray-300"
                                                            placeholder="Problem Name"
                                                            {...field}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-y-2 mt-2">
                                            <div className='flex items-center gap-x-4'>
                                                <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Number of Divisons<span className="text-red-500">*</span></span>
                                            </div>
                                            <Controller
                                                name="divisions"
                                                control={control}
                                                render={({ field }) => (
                                                    <select
                                                        {...field}
                                                        className="form-select w-full text-gray-300"
                                                    >
                                                        <option value="">Select Number of Divisions...</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                    </select>
                                                )}
                                            />
                                        </div>

                                        {numberOfDivisions && Array.from({ length: parseInt(numberOfDivisions) }, (_, index) => (
                                            <div key={index}>
                                                <div className={`flex flex-col gap-y-2 ${index > 0 ? 'mt-16' : ''}`}>
                                                    <div className='flex items-center gap-x-4'>
                                                        <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Link {index + 1}<span className="text-red-500">*</span></span>
                                                    </div>
                                                    <Controller
                                                        name={`link${index + 1}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder={`Link ${index + 1}`}
                                                                    {...field}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-y-2 mt-4">
                                                    <div className='flex items-center gap-x-4'>
                                                        <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">HTML Content {index + 1}<span className="text-red-500">*</span></span>
                                                    </div>

                                                    <RichEditor
                                                        name={`html${index + 1}`}
                                                        value={''}
                                                        options="Discussion"
                                                        {...register(`html${index + 1}`)}
                                                        onChange={onEditorContentChanged}
                                                        height="rich"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <div className='mt-20 flex flex-row  items-center gap-x-4'>
                                            <button className='btn text-[16px] font-medium bg-brand_50 border-brand_50 text-zinc-100' type='submit'>Save Draft</button>
                                        </div>
                                    </form>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddCourse