import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allQuestion: [],
    allOS: [],
    allDBMS: [],
    allCN: [],
    questionTopics: [],
    allColleges: [],
    loading: false,
};

const questionSlice = createSlice({
    name: "question",
    initialState: initialState,
    reducers: {
        setQuestionTopics(state, value) {
            state.questionTopics = value.payload;
        },
        setAllQuestions(state, value) {
            state.allQuestion = value.payload;
        },
        setAllOS(state, value) {
            state.allOS = value.payload;
        },
        setAllDBMS(state, value) {
            state.allDBMS = value.payload;
        },
        setAllCN(state, value) {
            state.allCN = value.payload;
        },
        setAllColleges(state, value) {
            state.allColleges = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
    },
});

export const { setQuestionTopics, setAllQuestions, setLoading, setAllColleges, setAllOS, setAllDBMS, setAllCN } = questionSlice.actions;

export default questionSlice.reducer;
