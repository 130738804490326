import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteArticle } from '../../../services/operations/blogs'
import { formatDate } from '../../../utils/Utils'

const TableItem = (props) => {

    const { signinData } = useSelector((state) => (state.auth))

    const dispatch = useDispatch()
    const email = signinData?.email

    const handleDeleteBlog = () => {
        dispatch(deleteArticle(props?.href))
    }

    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.id}.
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.title}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.author ? props?.author : email}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.category}
                    </div>
                </td>

                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {formatDate(props?.date)}
                    </div>
                </td>
                {props?.page === 'Draft' && (
                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                            {props?.state === 1 ? 'Pending' : 'Draft'}
                        </div>
                    </td>
                )}
                {props?.page === 'Published' && (
                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 break-normal">
                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                            {props?.slug}
                        </div>
                    </td>
                )}
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        {props.showDelete ? (
                            <button onClick={handleDeleteBlog} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>
                        ) : (null)}

                        {((props?.page === "Draft" && props?.state !== 1) || props?.page === 'Pending') && (
                            <Link to={props?.page === 'Pending' ? `/write/pending/${props?.href}/edit` : `/write/draft/${props?.href}/edit`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                        )}
                        {signinData?.role === 'Admin' && (
                            <>
                                {props?.page === "Published" && (
                                    <Link to={`/write/published/${props?.href}/edit`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                                )}
                            </>
                        )}
                        {props?.page === "Published" && (
                            <Link to={`/write/published-preview/${props?.href}`} target='_blank' className='btn bg-orange-500 text-white  border-orange-600'>View</Link>
                        )}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        {props?.page === "Published" && (
                            <Link to={`https://takeuforward.org/${props?.category}/${props?.slug}`} target='_blank' className='btn bg-red-500 text-white  border-red-600'>Link</Link>
                        )}
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default TableItem