import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getArticle, getBlogTopics, publishBlog, revertBlog, saveBlog } from '../../../services/operations/blogs';
// import toast from 'react-hot-toast';

const EditPending = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { handleSubmit, reset, control, formState: { errors } } = useForm({
        defaultValues: '',
    });

    const { signinData } = useSelector((state) => (state.auth))

    const { blog, blogCategories, blogTopics } = useSelector((state) => state.blogs)

    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    // console.log(params.id)
    useEffect(() => {
        dispatch(getArticle(params.id))
    }, [dispatch, params.id])
    useEffect(() => {
        dispatch(getBlogTopics())
    }, [dispatch])

    useEffect(() => {
        // Set default values when personalInfo changes
        reset(blog);
    }, [blog, reset]);

    // console.log(blog)

    const handleOnSubmit = (data) => {
        const { title, category_id, topics, slug, content, video, comments } = data
        dispatch(saveBlog(params.id, title, category_id, topics, slug, content, video, comments, navigate, 'pending'))
    }
    const handleRevert = (data) => {
        const { title, category_id, topics, slug, content, video, comments } = blog
        // if (title && category_id && topics && slug && content && video) {
            dispatch(revertBlog(params.id, title, category_id, topics, slug, content, video, comments, navigate))
        // } else {
        //     toast.error("Fill All Fields and Save Blog")
        // }
    }

    const handlePublish = () => {
        const { title, category_id, topics, slug, content, video, comments } = blog
        // if (title && category_id && topics && slug && content && video) {
            dispatch(publishBlog(params.id, title, category_id, topics, slug, content, video, comments, navigate))
        // } else {
        //     toast.error("Fill All Fields and Save Blog")
        // }
    }


    useEffect(() => {
        if (signinData?.role === 'Intern') {
            navigate('/write/draft')
        }
    }, [navigate, signinData?.role])

    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className=" mb-8">
                            <div className="flex-grow font-primary">
                                <section className='p-4'>
                                    <form onSubmit={handleSubmit(handleOnSubmit)} className="flex flex-col mt-6 gap-y-6">
                                        <div className="flex flex-col gap-y-2">
                                            <div className='flex items-center gap-x-4'>
                                                <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Title<span className="text-red-500">*</span></span>
                                                {errors?.title && (
                                                    <span className="text-red-500">{errors?.title.message}</span>
                                                )}
                                            </div>
                                            <Controller
                                                name="title"
                                                control={control}
                                                defaultValue=''
                                                rules={{ required: 'Title is required' }}
                                                render={({ field, fieldState }) => (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            className="form-input w-full text-gray-300"
                                                            placeholder="Title"
                                                            {...field}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className='mt-6 gap-y-10 flex flex-col'>
                                            <div className="flex flex-col gap-y-2 mt-2">
                                                <div className='flex items-center gap-x-4'>
                                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Category<span className="text-red-500">*</span></span>
                                                    {errors?.category && (
                                                        <span className="text-red-500">{errors?.category.message}</span>
                                                    )}
                                                </div>
                                                <Controller
                                                    name="category_id"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            className="form-select w-full text-gray-300"
                                                        >
                                                            <option value="">Select Category...</option>
                                                            {blogCategories?.length > 0 && (
                                                                blogCategories?.map((category) => (
                                                                    <option key={category?.category_id} value={category?.category_id}>{category?.category_tag}</option>
                                                                ))
                                                            )}
                                                        </select>
                                                    )}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-y-2">
                                                <div className='flex items-center gap-x-4'>
                                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Related Topics<span className="text-red-500">*</span></span>
                                                    {errors?.related_topics && (
                                                        <span className="text-red-500">{errors?.related_topics.message}</span>
                                                    )}
                                                </div>
                                                <Controller
                                                    name="topics"
                                                    control={control}
                                                    rules={{ required: 'Topics is required' }}
                                                    render={({ field, fieldState }) => (
                                                        <div>
                                                            <Select
                                                                isMulti
                                                                name="topics"
                                                                options={blogTopics?.length > 0 ? blogTopics : []}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                {...field}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-y-2">
                                                <div className='flex items-center gap-x-4'>
                                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Slug<span className="text-red-500">*</span></span>
                                                    {errors?.slug && (
                                                        <span className="text-red-500">{errors?.slug.message}</span>
                                                    )}
                                                </div>
                                                <Controller
                                                    name="slug"
                                                    control={control}
                                                    defaultValue=''
                                                    rules={{ required: 'Slug is required' }}
                                                    render={({ field, fieldState }) => (
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="slug"
                                                                className="form-input w-full text-gray-300"
                                                                placeholder="Slug"
                                                                {...field}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-y-2">
                                                <div className='flex items-center gap-x-4'>
                                                    <span className="text-gray-800 dark:text-zinc-300">Content<span className="text-red-500">*</span></span>
                                                    {errors?.content && (
                                                        <span className="text-red-500">{errors?.content.message}</span>
                                                    )}
                                                </div>
                                                <Controller
                                                    name="content"
                                                    control={control}
                                                    defaultValue=''
                                                    render={({ field }) => (
                                                        <textarea
                                                            type="text"
                                                            rows={10}
                                                            name="content"
                                                            className="form-input w-full text-gray-300"
                                                            placeholder="Content"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-y-2">
                                                <div className='flex items-center gap-x-4'>
                                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Video ID (https://www.youtube.com/watch?v=
                                                        )<span className="text-red-500">*</span></span>
                                                    {errors?.video && (
                                                        <span className="text-red-500">{errors?.video.message}</span>
                                                    )}
                                                </div>
                                                <Controller
                                                    name="video"
                                                    control={control}
                                                    defaultValue=''
                                                    render={({ field, fieldState }) => (
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="video"
                                                                className="form-input w-full text-gray-300"
                                                                placeholder="video id"
                                                                {...field}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-y-2">
                                                <div className='flex items-center gap-x-4'>
                                                    <span className="text-gray-800 dark:text-zinc-300">Comments (for internal use only)<span className="text-red-500">*</span></span>
                                                    {errors?.comments && (
                                                        <span className="text-red-500">{errors?.comments.message}</span>
                                                    )}
                                                </div>
                                                <Controller
                                                    name="comments"
                                                    control={control}
                                                    defaultValue=''
                                                    render={({ field }) => (
                                                        <textarea
                                                            type="text"
                                                            rows={6}
                                                            name="comments"
                                                            className="form-input w-full text-gray-300"
                                                            placeholder="Comments (for internal use only)"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className='mt-8 flex flex-row  items-center gap-x-4'>
                                            <button className='btn text-[16px] font-medium bg-brand_50 border-brand_50 text-zinc-100' type='submit'>Save</button>
                                            <button className='btn text-[16px] font-medium bg-red-500 border-red-600 text-zinc-100' onClick={handleRevert}>Revert to Draft</button>
                                            <button className='btn text-[16px] font-medium bg-green-500 border-green-600 text-zinc-100' onClick={handlePublish}>Publish</button>
                                            <Link to={`/write/preview/${params.id}`} target='_blank' className='btn text-[16px] font-medium' >Preview</Link>
                                        </div>
                                    </form>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default EditPending