import toast from "react-hot-toast"
import { setAllDrafts, setAllPending, setAllPublished, setProblem } from "../../slices/problemSlice"
import { mergeObjects } from "../../utils/Utils"
import { problemSetterEndpoints } from "../api"
import { apiConnector } from "../apiConnector"


const { GET_DETAILS, GET_PUBLISHED_DETAILS, SAVE_DRAFT, NEW_DRAFT, GET_DRAFT, SAVE_REVIEW_DRAFT, GET_REVIEW_DRAFT, EDIT_PUBLISHED, GET_PUBLISHED_DRAFT, UPDATE_STATUS, DELETE_DRAFT, SAVE_PUBLISHED } = problemSetterEndpoints

export function newDraft(email, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", NEW_DRAFT, { email })

            if (response.data.success) {
                navigate(`/problem-setter/draft/${response.data.problem_id}`)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function deleteDraft(problem_id, email) {
    return async (dispatch) => {
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await apiConnector("DELETE", DELETE_DRAFT, { problem_id })
            dispatch(getDrafts(email))

        } catch (error) {
            // console.log("NEW_DRAFT ERROR............", error)
        }
    }
}

export function saveDraft(problem_desc, navigate, email, to) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", SAVE_DRAFT, problem_desc)
            if (response.data.success) {
                dispatch(getDrafts(email))
                toast.success("Saved Successfully")
                navigate(`/problem-setter/${to}`)
            }
        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}

export function savePublish(data, problem_id, navigate, email, to) {
    return async (dispatch) => {
        try {
            console.log({ data, problem_id, navigate, email, to })
            const response = await apiConnector("PUT", SAVE_PUBLISHED, { email, problem_id, data })
            if (response.data.success) {
                dispatch(getDrafts(email))
                toast.success("Saved Successfully")
                navigate(`/problem-setter/${to}`)
            }
        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}

export function getProblemDetails(id) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_DETAILS + id)
            dispatch(setProblem(mergeObjects(response.data.data, response.data.logicalData)))

        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}

export function getPublishedProblemDetails(id) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_PUBLISHED_DETAILS + id)
            console.log(response.data)
            dispatch(setProblem(mergeObjects(response.data.data, response.data.logicalData)))

        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}
export function getDrafts(email) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_DRAFT + email)
            // console.log("Save Draft Data: ", response.data)
            dispatch(setAllDrafts(response.data.draftData))

        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}

export function getPending(email) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_REVIEW_DRAFT + email)
            // console.log("Save Draft Data: ", response.data)
            dispatch(setAllPending(response.data.draftData))

        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}

export function getPublished(email) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_PUBLISHED_DRAFT + email)
            // console.log("Save Draft Data: ", response.data)
            dispatch(setAllPublished(response.data.publishedData))

        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}

export function saveReview(problem_id, navigate, email) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", SAVE_REVIEW_DRAFT, { problem_id })
            console.log(response.data)
            if (response.data.success) {
                dispatch(getDrafts(email))
                toast.success("Submitted for review.")
                navigate('/problem-setter/pending')
            } else {
                toast.error(response.data.result.message)
            }

        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}

export function editPublish(problem_id, navigate, email, to) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        try {
            const response = await apiConnector("PUT", EDIT_PUBLISHED, { problem_id, email })
            if (response.data.success) {
                dispatch(getPublished(email))
                toast.success("Published Successfully")
                dispatch(deleteDraft(problem_id))
                navigate(`/problem-setter/${to}`)
            }

        } catch (error) {
            toast.error(error.response.data.message)
        }
        toast.dismiss(toastId);
    }
}

export function updateStatus(problem_id, navigate) {
    return async (dispatch) => {
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await apiConnector("POST", UPDATE_STATUS, { problem_id })
            toast.success("Article Moved to Draft")
            navigate('/problem-setter/pending')

        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}
