import React from 'react';
import TopicTableItem from './TopicTableItem';

function TopicTable({ topics }) {


    return (
        <div className="font-primary  relative">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/3 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold flex items-start">Name</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Status</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            topics?.map((data, index) => {
                                return (
                                    <TopicTableItem
                                        id={data?.id}
                                        num={index + 1}
                                        college={data?.college}
                                        status={data?.verified}
                                    />
                                )
                            })
                        }
                    </table>
                </div>
            </div>
            {/* <div className="dark:bg-dark_50  border-2 border-t-0 border-lead100 dark:border-lead500">
                <TopicTablePagination
                    itemsPerPage={itemsPerPage}
                    totalItems={topics?.length}
                    currentPage={currentPage}
                    paginate={paginate}
                    indexOfFirstItem={indexOfFirstItem}
                    indexOfLastItem={indexOfLastItem}
                />
            </div> */}
        </div>
    );
}

export default TopicTable;
