import Editor from '@monaco-editor/react';
import {  useState } from 'react';
import { BadgeX, CheckCircle2, Play } from 'lucide-react'
import React from 'react'
import { apiConnector } from '../../services/apiConnector';
import { problemSetterEndpoints } from '../../services/api';

const { RUN_SETTER_CODE } = problemSetterEndpoints
const CodeEditor = ({ code, language, io }) => {

    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)



    const handleRunButtonClick = async () => {
        setLoading(true)
        const response = await apiConnector("POST", RUN_SETTER_CODE, { code, language, io })
        setResults(response.data.results)
        setLoading(false)
    };

    const options = {
        readOnly: false,
        minimap: { enabled: false },
    };


    return (
        <div className='w-full'>
            <div className='flex flex-col'>
                <div className='flex flex-row justify-between items-center p-2 border-b-2 border-zinc-300 dark:border-zinc-800 dark:bg-[#191919] bg-[#f8f8f8]'>
                    <div className='ml-2'>
                        <span className='dark:text-zinc-200'>Languge: {language}</span>
                    </div>
                    <button className='flex items-center gap-x-2 p-2 rounded-lg bg-zinc-300 dark:bg-zinc-800' onClick={handleRunButtonClick}>
                        {loading ? (
                            <>
                                <Play size={14} className='fill-black dark:fill-light_50 dark:stroke-light_50' />
                                <span className='dark:text-light_50 text-zinc-900'>Running...</span>
                            </>
                        ) : (
                            <>
                                <Play size={14} className='fill-black dark:fill-light_50 dark:stroke-light_50' />
                                <span className='dark:text-light_50 text-zinc-900'>Run</span>
                            </>
                        )}

                    </button>
                </div>
            </div>
            <div className='p-1 bg-[#1E1E1E] rounded-b-lg h-full w-full border-zinc-300 dark:border-zinc-700'>
                <Editor
                    options={options}
                    height={400}
                    width={600}
                    theme="vs-dark"
                    language={language}
                    value={code}
                />
            </div>
            <div className='mt-4 w-1/2'>
                <div className='flex flex-col'>
                    <div className='flex flex-col gap-y-2'>
                        <div className='flex flex-col'>
                            {results && results?.length > 0 ? results?.map((data, index) => (
                                <div key={index} className='bg-[#E9E9E9] dark:bg-dark_50 border border-gray-200 dark:border-zinc-800 p-2 flex flex-col items-start justify-between rounded-lg mt-8 gap-y-4' >
                                    <div className='flex justify-between w-full'>
                                        <div>
                                            <p className='text-zinc-900 dark:text-zinc-200 font-medium'>
                                                Input {index + 1}: <span className='font-bold'>{data.realInput}</span></p>
                                        </div>
                                        <div>
                                            {data.status === 'Success' && (
                                                <CheckCircle2 size={19} strokeWidth={2} className='stroke-green-600' />
                                            )}
                                            {data.status === 'Failed' && (
                                                <BadgeX size={19} strokeWidth={2} className='stroke-red-600' />
                                            )}
                                        </div>
                                    </div>
                                    <p className='text-zinc-900 dark:text-zinc-200 font-medium'>Output Provided:
                                        <span className='font-bold'>
                                            {data.realOutput}
                                        </span>
                                    </p>
                                    <p className='text-zinc-900 dark:text-zinc-200 font-medium'>Stdout:
                                        <span className='font-bold'>
                                            {data.stdout}
                                        </span>
                                    </p>
                                    {data.stderr.length > 0 && (
                                        <p className='text-zinc-900 dark:text-zinc-200 font-medium'>Error:
                                            <span className='font-bold'>
                                                {data.stderr}
                                            </span>
                                        </p>
                                    )}
                                    <div>
                                    </div>
                                </div>
                            )) : (
                                <div>
                                    {io?.map((data, index) => (
                                        <div key={index} className='bg-[#E9E9E9] dark:bg-dark_50 border border-gray-200 dark:border-zinc-800 p-2 flex items-start justify-between rounded-lg mt-8 ' >
                                            <div>
                                                <p className='text-zinc-900 dark:text-zinc-200 font-medium'>
                                                    Input {index + 1}: <span className='font-bold'>{data.input}</span></p>
                                            </div>
                                            <div>
                                                <CheckCircle2 size={19} strokeWidth={2} className='stroke-gray-600' />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CodeEditor