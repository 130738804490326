import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select';
import { apiConnector } from '../../services/apiConnector';
import { blogsEndpoints } from '../../services/api';
import toast from 'react-hot-toast';

const { SET_QUESTION_DIFF } = blogsEndpoints

const TopicTableItem = (props) => {

    const { blogTopics } = useSelector((state) => state.blogs)


    const [selectedDifficulty, setSelectedDifficulty] = useState("");
    const [topics, setTopics] = useState([])
    const [postLink, setPostLink] = useState(props?.post_link || "");
    const [title, setTitle] = useState(props?.title || "");
    const [ytLink, setYTLink] = useState(props?.yt_link || "");
    const [csLink, setCsLink] = useState(props?.cs_link || "");
    const [lcLink, setLcLink] = useState(props?.lc_link || "");


    // console.log()
    useEffect(() => {
        const difficultyAsNumber = typeof props.difficulty === 'string' ? parseInt(props.difficulty) : props.difficulty;

        setSelectedDifficulty(difficultyAsNumber || "");
        setTopics(props?.topics ? JSON.parse(props?.topics) : []);
    }, [props.difficulty, props?.topics]);

    const handleDifficultyChange = (event) => {
        setSelectedDifficulty(event.target.value);
    };

    const handleInput = (event, setter) => {
        setter(event.target.value);
    };

    const handleSave = async () => {
        try {
            const response = await apiConnector("PUT", SET_QUESTION_DIFF + `${props?.id}`, {
                difficulty: selectedDifficulty,
                topics: topics,
                post_link: postLink,
                title: title,
                cs_link: csLink,
                lc_link: lcLink,
                yt_link: ytLink,
            })

            if (response.data.success) {
                toast.success("Difficulty and Topics Set successfully")
            } else {
                toast.error("Failed to set Difficulty and Topics")
            }
        } catch (error) {
            console.log(error)
        }
    }
    // console.log(topics)

    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                {/* <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.num}.
                    </div>
                </td> */}
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 ">
                    {/* <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start"> */}
                    <input className='form-input w-10/12' type="text" value={title} onChange={(e) => handleInput(e, setTitle)} />
                    {/* </div> */}
                </td>
                <td className="px-2  border-l-2 border-lead100 dark:border-lead500 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start w-full">
                        <input className='form-input w-10/12' type="text" value={postLink} onChange={(e) => handleInput(e, setPostLink)} />
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        <input className='form-input w-10/12' type="text" value={ytLink} onChange={(e) => handleInput(e, setYTLink)} />
                    </div>
                </td>
                <td className="px-2  border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        <input className='form-input w-10/12' type="text" value={csLink} onChange={(e) => handleInput(e, setCsLink)} />
                    </div>
                </td>
                <td className="px-2  border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        <input className='form-input w-10/12' type="text" value={lcLink} onChange={(e) => handleInput(e, setLcLink)} />
                    </div>
                </td>

                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        <select
                            className="form-select text-gray-300"
                            value={selectedDifficulty}
                            onChange={handleDifficultyChange}
                        >
                            <option value="">Select Difficulty...</option>
                            <option value="0">Easy</option>
                            <option value="1">Medium</option>
                            <option value="2">Hard</option>
                        </select>

                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap relative">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        <Select
                            isMulti
                            name="topics"
                            options={blogTopics?.length > 0 ? blogTopics : []}
                            isSearchable={true}
                            isClearable={true}
                            className="w-full "
                            classNamePrefix="select"
                            value={topics}
                            onChange={setTopics}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuShouldBlockScroll={true}
                        />
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handleSave} className='btn bg-green-500 text-white  border-gree-500'>Save</button>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default TopicTableItem