import React, { useState } from 'react';
import { isObjectEmpty } from '../../utils/Utils';

function Accordion(props) {

    const [open, setOpen] = useState(false);
    const handleAccordionClick = (event) => {
        // Stop event propagation to prevent form submission
        event.preventDefault();
        setOpen(!open);
    };

    return (
        <div className="px-3 py-2 rounded-lg border border-gray-200 dark:border-zinc-800">
            <button
                className="flex items-center justify-between w-full group mb-1"
                aria-expanded={open}
                onClick={handleAccordionClick}
            >

                <div className="text-lg text-gray-800 dark:text-zinc-300 font-medium">{props.title} <span className="text-red-500">*</span> {props.errors && !isObjectEmpty(props.errors) && (
                    <span className="text-red-500 ml-3 text-lg">Required</span>
                )}</div>
                
                <svg className={`w-8 h-8 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-3 ${open && 'rotate-180'}`} viewBox="0 0 32 32">
                    <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                </svg>
            </button>
            <div className={`text-sm dark:text-zinc-300 p-4 ${!open && 'hidden'}`}>
                {props.children}
            </div>
        </div>
    );
}

export default Accordion;
