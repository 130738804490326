/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { htmlToMarkdown, markdownToHtml } from "./Parser";

import "react-quill/dist/quill.snow.css";
import { TOOLBAR_OPTIONS_DISCUSSION, TOOLBAR_OPTIONS_NOTES, TOOLBAR_OPTIONS_DRAFT, hasHtmlTags } from "../../utils/Utils";
import { useEffect } from "react";

export default function RichEditor(props) {

    // console.log(props.onFocus)
    const [value, setValue] = useState(markdownToHtml(props.value || ""));

    useEffect(() => {
        if (hasHtmlTags(props.value)) {
            setValue(htmlToMarkdown(props.value || ""));
        } else {
            setValue(markdownToHtml(props.value || ""))
        }
    }, [props.value])

    const reactQuillRef = useRef(null);

    const onChange = (content) => {
        setValue(content);

        if (props.onChange) {
            props.onChange({
                name: props.name,
                html: content,
                markdown: htmlToMarkdown(content)
            });
        }
    };

    useEffect(() => {
        if (props?.isSubmitted) {
            setValue('');
        }
    }, [props?.isSubmitted])

    const TOOLBAR_OPTIONS =
        props.options === "Notes"
            ? TOOLBAR_OPTIONS_NOTES
            : props.options === "Draft"
                ? TOOLBAR_OPTIONS_DRAFT
                : TOOLBAR_OPTIONS_DISCUSSION;
    return (
        <ReactQuill
            ref={reactQuillRef}
            theme="snow"
            placeholder="Start writing..."
            modules={{
                toolbar: {
                    container: TOOLBAR_OPTIONS,
                },
            }}
            value={value}
            className={props?.height === 'rich' && 'rich-editor-height'}
            onChange={onChange}
        />
    )

}
