import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import Table from '../../../components/BlogWrite/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogsPendings } from '../../../services/operations/blogs';
import { useNavigate } from 'react-router-dom';

const Pending = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { allPending } = useSelector((state) => state.blogs)

    const { signinData } = useSelector((state) => (state.auth))

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getBlogsPendings())
    }, [dispatch])
    
    useEffect(() => {
        if (signinData?.role === 'Intern') {
            navigate('/write/draft')
        }
    }, [navigate, signinData?.role])

    const [searchQuery, setSearchQuery] = useState('');

    const filtered = allPending.filter(topic =>
        topic['title']?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                    <div className="sm:flex sm:justify-between sm:items-center mb-8">
                            <div className="relative ml-auto">
                                <label className="sr-only">Search</label>
                                <input
                                    className="w-full border rounded-lg focus:ring-transparent placeholder-gray-400 appearance-none py-2 pl-10 pr-4 dark:bg-dark_50 dark:placeholder:text-zinc-200 dark:text-zinc-200 border-slate-300 bg-light_10/20 placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 hover:border-slate-400 dark:hover:border-slate-500 bg-white"
                                    type="search"
                                    placeholder="Search Blogs..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button
                                    className="absolute inset-1 right-auto group  rounded-md"
                                    aria-label="Search"
                                >
                                    <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-600 ml-2 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="mb-8">
                            <div className="flex-grow font-primary">
                                <section className=''>
                                    <div className="flex flex-col">
                                        <div className="mt-6">
                                            {filtered?.length > 0 ? (
                                                <Table showDelete={false} page="Pending" topics={filtered} />
                                            ) : (
                                                <div className="flex justify-center items-center">
                                                    <h1 className="text-xl font-medium dark:text-zinc-200">There are no Blogs in this category.</h1>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Pending