import React, { useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import { apiConnector } from '../../services/apiConnector';
import { successStoriesEndpoints } from '../../services/api';
import toast from 'react-hot-toast';


const { ADD_STORY } = successStoriesEndpoints
const AddSuccess = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: '',
    });


    const handleOnSubmit = async (data) => {
        const response = await apiConnector('POST', ADD_STORY, { data })
        if(response.data.success) {
            reset()
            // navigate('/success-stories')
            toast.success(response.data.message)
        }
    }

    return (

        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>

                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <form onSubmit={handleSubmit(handleOnSubmit)} className="flex flex-col mt-6 gap-y-6 p-10">
                            <div className="flex flex-col gap-y-2">
                                <div className='flex items-center gap-x-4'>
                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">User Name<span className="text-red-500">*</span></span>
                                    {errors?.name && (
                                        <span className="text-red-500">{errors?.name.message}</span>
                                    )}
                                </div>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=''
                                    rules={{ required: 'Name is required' }}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-input w-full text-gray-300"
                                                placeholder="User Name"
                                                {...field}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col gap-y-2">
                                <div className='flex items-center gap-x-4'>
                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">User Image  Link (S3)</span>
                                    {errors?.imageLink && (
                                        <span className="text-red-500">{errors?.imageLink.message}</span>
                                    )}
                                </div>
                                <Controller
                                    name="imageLink"
                                    control={control}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <input
                                                type="text"
                                                name="imageLink"
                                                className="form-input w-full text-gray-300"
                                                placeholder="User Image  Link (S3)"
                                                {...field}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col gap-y-2">
                                <div className='flex items-center gap-x-4'>
                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">User LinkedIn Link<span className="text-red-500">*</span></span>
                                    {errors?.userLinkedIn && (
                                        <span className="text-red-500">{errors?.userLinkedIn.message}</span>
                                    )}
                                </div>
                                <Controller
                                    name="userLinkedIn"
                                    control={control}
                                    defaultValue=''
                                    rules={{ required: 'LinkedIn Link is required' }}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <input
                                                type="text"
                                                name="userLinkedIn"
                                                className="form-input w-full text-gray-300"
                                                placeholder="User LinkedIn Link"
                                                {...field}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col gap-y-2">
                                <div className='flex items-center gap-x-4'>
                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Description<span className="text-red-500">*</span></span>
                                    {errors?.description && (
                                        <span className="text-red-500">{errors?.description.message}</span>
                                    )}
                                </div>
                                <Controller
                                    name="description"
                                    control={control}
                                    defaultValue=''
                                    rules={{ required: 'description is required' }}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <textarea
                                                type="text"
                                                rows={6}
                                                name="description"
                                                className="form-input w-full text-gray-300"
                                                placeholder="Description"
                                                {...field}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col gap-y-2">
                                <div className='flex items-center gap-x-4'>
                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Company Name<span className="text-red-500">*</span></span>
                                    {errors?.companyName && (
                                        <span className="text-red-500">{errors?.companyName.message}</span>
                                    )}
                                </div>
                                <Controller
                                    name="companyName"
                                    control={control}
                                    defaultValue=''
                                    rules={{ required: 'Company Name is required' }}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <input
                                                type="text"
                                                name="companyName"
                                                className="form-input w-full text-gray-300"
                                                placeholder="Company Name"
                                                {...field}
                                            />
                                        </div>
                                    )}
                                />
                            </div>

                            <div className='mt-8 flex flex-row  items-center gap-x-4'>
                                <button className='btn text-[16px] font-medium bg-brand_50 border-brand_50 text-zinc-100' type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddSuccess