import { useSelector } from 'react-redux';


export const formatThousands = (value) => Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    notation: 'compact',
}).format(value);

export function isObjectEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return null; // Return null if the cookie is not found
}


export function setObjectInCookie(cookieName, objectValue, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    // Convert the object to a JSON string
    const jsonString = JSON.stringify(objectValue);

    // Encode the cookie name and value, and set the cookie
    const cookieString = `${encodeURIComponent(cookieName)}=${encodeURIComponent(jsonString)}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
}
export function deleteCookie(cookieName) {
    // Set the expiration date in the past
    const expirationDate = new Date(0);

    // Encode the cookie name and set the cookie with an expired date
    const cookieString = `${encodeURIComponent(cookieName)}=; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
}

// Function to get an object from a cookie
export function getObjectFromCookie(cookieName) {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${encodeURIComponent(cookieName)}=`));

    if (cookieValue) {
        // Decode the cookie value and parse the JSON string to get the object
        const jsonString = decodeURIComponent(cookieValue.split('=')[1]);
        return JSON.parse(jsonString);
    }

    return null; // Return null if the cookie is not found
}

export function isEmptyString(value) {
    return typeof value === 'string' && value.trim() === '';
}

export function mergeTopics(...sheets) {
    // Extract topics from each sheet
    const allTopics = sheets.flatMap(sheet => {
        if (sheet.sheetData) {
            // For sheets similar to sdeSheet
            return sheet.sheetData.flatMap(entry => entry.topics);
        } else if (sheet.sub_steps) {
            // For sheets similar to a2zSheet
            return sheet.sub_steps.flatMap(step => step.topics);
        } else if (sheet.sheetData) {
            // For sheets similar to updated a2zSheet
            return sheet.sheetData.flatMap(entry => entry.sub_steps.flatMap(step => step.topics));
        }
        return [];
    });

    return allTopics;
}

export function mergeA2ZTopics(a2zSheet) {
    if (a2zSheet.sheetData) {
        // If a2zSheet structure is similar to sdeSheet
        return a2zSheet.sheetData.flatMap(entry => entry.sub_steps.flatMap(step => step.topics));
    } else if (a2zSheet.sub_steps) {
        // If a2zSheet structure is similar to itself
        return a2zSheet.sub_steps.flatMap(step => step.topics);
    }
    return [];
}

export function mergeArrays(arr1, arr2) {
    return arr1.concat(arr2);
}
export function parseArray(arr) {
    return arr?.map(element => JSON.parse(element));
}

export function createSavedNotes(notes, allTopics) {
    const savedNotes = [];

    for (const noteKey in notes) {
        const matchingTopic = allTopics?.find(topic => topic?.id === noteKey);

        if (matchingTopic) {
            // Create a new object with the same properties and add the 'note' property
            const topicWithNote = { ...matchingTopic, note: notes[noteKey] };
            savedNotes.push(topicWithNote);
        }
    }

    return savedNotes;
}

export function mergeTopicsA2Z(object) {
    if (!object || !object?.sub_steps) {
        console.error("Invalid object format");
        return [];
    }

    const mergedArray = [];

    object?.sub_steps?.forEach((subStep) => {
        if (subStep?.topics) {
            mergedArray.push(...subStep.topics);
        }
    });

    return mergedArray;
}

export function extractYouTubeVideoId(youtubeLink) {
    // Regular expression to match the YouTube video ID in the longer link
    const longLinkRegex = /[?&]v=([^&]+)/;

    // Regular expression to match the YouTube video ID in the short link
    const shortLinkRegex = /youtu\.be\/([^&]+)/;

    // Use the regular expressions to extract the video ID
    const longLinkMatch = youtubeLink?.match(longLinkRegex);
    const shortLinkMatch = youtubeLink?.match(shortLinkRegex);

    // Check if a match is found in the long link
    if (longLinkMatch && longLinkMatch[1]) {
        return longLinkMatch[1];
    }
    // Check if a match is found in the short link
    else if (shortLinkMatch && shortLinkMatch[1]) {
        return shortLinkMatch[1];
    } else {
        // If no match is found, handle the error or return null
        // console.error('Invalid YouTube link');
        return null;
    }
}


export const useArraySizeChanged = (inputArray) => {
    const { progress } = useSelector((state) => state.profile);

    const isSizeChanged = () => inputArray.length !== progress.length;

    return isSizeChanged();
};


export const findObject = (data, decodedStepTitle, decodedQuestionTitle, approachName) => {
    // Find the matching object
    const result = data?.flatMap((step) =>
        step?.sub_steps?.flatMap((sub) =>
            sub?.topics?.flatMap((topic) => {
                if (
                    topic?.step_title.toLowerCase() === decodedStepTitle.toLowerCase() &&
                    topic?.question_title.toLowerCase() === decodedQuestionTitle.toLowerCase()
                ) {
                    const approach = topic?.approaches.find(
                        (approach) => approach?.approah_name.toLowerCase() === approachName.toLowerCase()
                    );
                    return approach ? [approach] : [];
                }
                return [];
            })
        )
    );

    return result?.length > 0 ? result[0] : undefined;
};


export function hasHtmlTags(inputString) {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(inputString);
}

export function isLastObjectInArray(obj, array) {
    // Check if the array is not empty
    if (Array.isArray(array) && array.length > 0) {
        // Compare the given object with the last object in the array
        return obj === array[array.length - 1];
    }
    // If the array is empty, the object cannot be the last one
    return false;
}

export function hasOnlyOneObject(arr) {
    // Check if the input is an array and has exactly one element
    return Array.isArray(arr) && arr.length === 1 && typeof arr[0] === 'object';
}

export const TOOLBAR_OPTIONS_NOTES = [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'color': [] }],
    [{ 'align': [] }]
];

export const TOOLBAR_OPTIONS_DISCUSSION = [
    ['bold', 'italic', 'underline'],
    ['blockquote', 'code-block']
];
export const TOOLBAR_OPTIONS_DRAFT = [
    ['bold', 'italic', 'underline'],
    ['blockquote', 'code-block'],
    ["link", "image"]
];

export const getTimestamp = (createdAt) => {
    const now = new Date();
    const createdAtDate = new Date(createdAt);
    const secondsDiff = Math.floor((now - createdAtDate) / 1000);

    // Define time units in seconds
    const minute = 60;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    const month = 30 * day;
    const year = 365 * day;

    // Calculate time difference
    if (secondsDiff < minute) {
        return `${secondsDiff} second${secondsDiff !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < hour) {
        const minutes = Math.floor(secondsDiff / minute);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < day) {
        const hours = Math.floor(secondsDiff / hour);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < week) {
        const days = Math.floor(secondsDiff / day);
        return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < month) {
        const weeks = Math.floor(secondsDiff / week);
        return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < year) {
        const months = Math.floor(secondsDiff / month);
        return `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
        const years = Math.floor(secondsDiff / year);
        return `${years} year${years !== 1 ? 's' : ''} ago`;
    }
};

export const formatAndDivideNumber = (num) => {
    if (num >= 1000000) {
        const formattedNum = (num / 1000000).toFixed(1)
        return `${formattedNum} M`
    } else if (num >= 1000) {
        const formattedNum = (num / 1000).toFixed(1)
        return `${formattedNum} K`
    } else {
        return num.toString()
    }
}

export function hasAcceptedReply(data) {
    if (data && data?.replies && Array.isArray(data?.replies)) {
        // Check if any reply has isAccepted = 1
        return data?.replies?.some(reply => reply.isAccepted === 1);
    }

    // Return false if data or replies are not present
    return false;
}

export function mergeObjects(obj1, obj2) {
    return { ...obj1, ...obj2 };
}

export function generateId(inputString) {
    const lowercaseString = inputString.toLowerCase();

    const stringWithoutSpaces = lowercaseString.replace(/[\s,:.-]+/g, '');

    const processedString = stringWithoutSpaces.replace(/([a-z])\1+/g, '$1');

    const finalString = processedString.replace(/[()]/g, '');

    return finalString;
}



export function formatDate(dateString) {
    if (!dateString) {
        return "";
    }

    const months = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
}

export const convertSlugToReadable = (slug) => {
    // Split the slug by hyphens and capitalize each word
    const words = slug.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words with spaces
    return words.join(' ');
};

export function extractVideoId(url) {
    const regex = /[?&]v=([^?]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
        return match[1];
    } else {
        return null; // URL format doesn't match or video ID not found
    }
}

export function sortByNonVerified(array) {
    // Create a copy of the input array
    const newArray = [...array];

    // Sort the copied array based on verified property being 0
    const verifiedZeroArray = newArray?.filter(college => college?.verified === 0);
    const verifiedOneArray = newArray?.filter(college => college?.verified === 1);

    // Merge both arrays by placing 1st array before 2nd
    const mergedArray = [...verifiedZeroArray, ...verifiedOneArray];

    return mergedArray;
}

export function sortByNotClosedBugs(array) {
    // Create a copy of the input array
    const newArray = [...array];

    // Sort the copied array based on verified property being 0
    const verifiedZeroArray = newArray?.filter(college => college?.isClosed === 0);
    const verifiedOneArray = newArray?.filter(college => college?.isClosed === 1);

    // Merge both arrays by placing 1st array before 2nd
    const mergedArray = [...verifiedZeroArray.reverse(), ...verifiedOneArray];

    return mergedArray;
}

export function isArrayEmpty(arr) {
    // Check if the array is empty
    if (arr === undefined || arr.length === 0) {
        return true; // Array is empty
    } else {
        return false; // Array is not empty
    }
}

export function mergeAllData(objects) {
    return objects.reduce((acc, obj) => acc.concat(obj.data || []), []);
}