import toast from "react-hot-toast"
import { bugsEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import { setAllBugs } from "../../slices/bugSlice"

const { GET_ALL_BUGS, CLOSE_BUG } = bugsEndpoints

export function getAllBugs() {
    return async (dispatch, getState) => {
        try {
            // if (isArrayEmpty(getState().bug.allBugs)) {
                const response = await apiConnector("GET", GET_ALL_BUGS)
                dispatch(setAllBugs(response.data.result))
            // }

        } catch (error) {
            toast.error(error.response)
        }
    }
}
export function closeBug(id) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", CLOSE_BUG, { id })
            if(response.data.success) {
                dispatch(getAllBugs());
                toast.success("Bug Closed Successfully")
            }

        } catch (error) {
            toast.error(error.response)
        }
    }
}