import { BadgeX, Plus, XCircle } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Accordion from '../../../components/Accordion/Accordion';
import Header from '../../../components/Header/Header';
import RichEditor from '../../../components/RichEditor/RichEditor';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { getPublishedProblemDetails, savePublish } from '../../../services/operations/problemSetterAPI';

const EditPublished = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [inputOutputPairs, setInputOutputPairs] = useState([]);
    const [additionalFields, setAdditionalFields] = useState(0);
    const [edgeCasesValueArray, setEdgeCasesValueArray] = useState([])
    const { id } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { problem } = useSelector((state) => (state.problems))
    const { signinData } = useSelector((state) => (state.auth))
    const { register, handleSubmit, watch, setValue, reset, control, formState: { errors } } = useForm({
        defaultValues: problem,
    });

    const onEditorContentChanged = ({ name, html }) => {
        setValue(name, html);
    };

    const handleOnSubmit = (data) => {
        console.log(errors)
        const edgeCases = [];

        for (let i = 1; i <= additionalFields; i++) {
            const newObject = {
                input: data[`realInput${i + 3}`],
                output: data[`realOutput${i + 3}`],
            };

            edgeCases.push(newObject);
        }

        const email = signinData?.email

        const formData = {
            id: id,
            problem_name: data.problem_name,
            problem_statement: data.problem_statement,
            example1: data.example1,
            example2: data.example2,
            example3: data.example3,
            constraints: data.constraints,
            notes: data.notes,
            showInput1: data.showInput1,
            showInput2: data.showInput2,
            showInput3: data.showInput3,
            topics: data.topics,
            author_name: data.author_name,
            author_linkedin: data.author_linkedin,
            realInput1: data.realInput1,
            realInput2: data.realInput2,
            realInput3: data.realInput3,
            realOutput1: data.realOutput1,
            realOutput2: data.realOutput2,
            realOutput3: data.realOutput3,
            publicCpp: data.publicCpp,
            privateCpp: data.privateCpp,
            publicJava: data.publicJava,
            privateJava: data.privateJava,
            publicPy: data.publicPy,
            privatePy: data.privatePy,
            publicJs: data.publicJs,
            privateJs: data.privateJs,
            cppTimeLimit: data.cppTimeLimit,
            correctCodeLang: data.correctCodeLang,
            correctCode: data.correctCode,
            testCaseCode: data.testCaseCode,
            testCaseCode_lang: data.testCaseCode_lang,
            edgeCases: edgeCases
        }
        dispatch(savePublish(formData, id, navigate, email, "published"))
    }

    useEffect(() => {
        if (signinData?.role === 'Intern') {
            navigate('/problem-setter/published')
        }
    }, [navigate, signinData?.role])

    useEffect(() => {
        dispatch(getPublishedProblemDetails(id))
    }, [dispatch, id])

    // console.log(problem)
    useEffect(() => {
        // Set default values when personalInfo changes
        reset(problem);
    }, [problem, reset]);

    const removeTopic = (index) => {
        const topics = watch('topics') || [];
        const updatedTopics = [...topics.slice(0, index), ...topics.slice(index + 1)];
        setValue('topics', updatedTopics);
    };

    const handleAddFields = () => {
        setAdditionalFields((prevFields) => {
            const currentFields = prevFields + 1;

            console.log(currentFields)
            // Create a new object with the current input and output values
            const newPair = {
                input: watch(`realInput${currentFields}`),
                output: watch(`realOutput${currentFields}`),
            };

            // Add the new object to the array
            setInputOutputPairs((prevPairs) => [...prevPairs, newPair]);

            // Return the updated value for additionalFields
            return currentFields;
        });
    };
    useEffect(() => {

        const jsonString = problem?.edgeCases;

        if (jsonString) {
            try {
                const parsedArray = JSON.parse(jsonString);
                if (Array.isArray(parsedArray)) {
                    setEdgeCasesValueArray(parsedArray)
                    setAdditionalFields(parsedArray.length);
                }
            } catch (error) {
                // console.error('Error parsing JSON:', error);
            }
        }
    }, [problem?.edgeCases])

    const handleRemoveFields = () => {
        setAdditionalFields((prevFields) => prevFields - 1);

        // Remove the last object from the array
        setInputOutputPairs((prevPairs) => prevPairs.slice(0, -1));
    };

    const addTopic = () => {
        const topics = watch('topics') || [];
        const newTopic = watch('newTopic');

        if (newTopic && !topics.includes(newTopic)) {
            setValue('topics', [...topics, newTopic]);
            setValue('newTopic', '');
        }
    };



    return (
        <div className="flex  dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className=" mb-8">
                            <div className="flex-grow font-primary">
                                <section className='p-4'>
                                    <form onSubmit={handleSubmit(handleOnSubmit)} className="flex flex-col mt-6 gap-y-6">
                                        <div className="flex flex-col gap-y-2">
                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Problem Name<span className="text-red-500">*</span></span>
                                            {errors?.problem_name && (
                                                <span className="text-red-500">{errors?.problem_name.message}</span>
                                            )}
                                            <Controller
                                                name="problem_name"
                                                control={control}
                                                defaultValue={problem?.problem_name || ''}
                                                rules={{ required: 'Problem Name is required' }}
                                                render={({ field, fieldState }) => (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="problem_name"
                                                            className="form-input w-full text-gray-300"
                                                            placeholder="Problem Name"
                                                            {...field}
                                                        />
                                                        {fieldState?.error && (
                                                            <span className="text-red-500">{fieldState.error.message}</span>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className='mt-6'>
                                            <Accordion title="Section - 1">
                                                <div className="flex flex-col gap-y-6">
                                                    <div className="flex flex-col gap-y-2">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Topics <span className="text-red-500">*</span></span>
                                                            {errors?.topics && (
                                                                <span className="text-red-500">{errors?.topics.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="topics"
                                                            control={control}
                                                            defaultValue={[]}

                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <div className="flex flex-wrap gap-2">
                                                                        {field.value.map((topic, index) => (
                                                                            <div key={index} className="flex items-center bg-gray-200 p-2 rounded">
                                                                                <span className="mr-2">{topic}</span>
                                                                                <button type="button" onClick={() => removeTopic(index)}>
                                                                                    <XCircle size={18} className='stroke-red-500' />
                                                                                </button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        <input
                                                                            type="text"
                                                                            name="newTopic"
                                                                            className="form-input w-full text-gray-300"
                                                                            placeholder="Add new topic and press Enter + Ctrl"
                                                                            {...register('newTopic')}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter' && e.ctrlKey) {
                                                                                    e.stopPropagation(); // Stop event propagation
                                                                                    addTopic();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            rules={{ validate: (value) => value.length > 0 || 'Topics are required' }}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-2">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Problem Statement <span className="text-red-500">*</span></span>
                                                            {errors.problem_statement && (
                                                                <span className="text-red-500" >Please enter at least 10 characters.</span>
                                                            )}
                                                        </div>
                                                        <RichEditor
                                                            name="problem_statement"
                                                            value={problem?.problem_statement || ''}
                                                            options="Discussion"
                                                            {...register('problem_statement', { required: 'Content should have more than 10 characters.', minLength: { value: 10, message: 'Content should have more than 10 characters.' } })}
                                                            onChange={onEditorContentChanged}
                                                            height="rich"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-16">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Example 1 <span className="text-red-500">*</span></span>
                                                            {errors.example1 && (
                                                                <span className="text-red-500" >Please enter at least 10 characters.</span>
                                                            )}
                                                        </div>
                                                        <RichEditor
                                                            name="example1"
                                                            value={problem?.example1 || ''}
                                                            options="Draft"
                                                            {...register('example1', { required: 'Content should have more than 10 characters.', minLength: { value: 10, message: 'Content should have more than 10 characters.' } })}
                                                            onChange={onEditorContentChanged}
                                                            height="rich"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-16">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Example 2 <span className="text-red-500">*</span></span>
                                                            {errors.example2 && (
                                                                <span className="text-red-500" >Please enter at least 10 characters.</span>
                                                            )}
                                                        </div>
                                                        <RichEditor
                                                            name="example2"
                                                            value={problem?.example2 || ''}
                                                            options="Draft"
                                                            {...register('example2', { required: 'Content should have more than 10 characters.', minLength: { value: 10, message: 'Content should have more than 10 characters.' } })}
                                                            onChange={onEditorContentChanged}
                                                            height="rich"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-16">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Example 3 <span className="text-red-500">*</span></span>
                                                            {errors.example3 && (
                                                                <span className="text-red-500" >Please enter at least 10 characters.</span>
                                                            )}
                                                        </div>
                                                        <RichEditor
                                                            name="example3"
                                                            value={problem?.example3 || ''}
                                                            options="Draft"
                                                            {...register('example3', { required: 'Content should have more than 10 characters.', minLength: { value: 10, message: 'Content should have more than 10 characters.' } })}
                                                            onChange={onEditorContentChanged}
                                                            height="rich"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-16">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Constraints<span className="text-red-500">*</span></span>
                                                            {errors.constraints && (
                                                                <span className="text-red-500" >Please enter at least 10 characters.</span>
                                                            )}
                                                        </div>
                                                        <RichEditor
                                                            name="constraints"
                                                            value={problem?.constraints || ''}
                                                            options="Discussion"
                                                            {...register('constraints', { required: 'Content should have more than 10 characters.', minLength: { value: 10, message: 'Content should have more than 10 characters.' } })}
                                                            onChange={onEditorContentChanged}
                                                            height="rich"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-16 text-[16px] font-medium">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Note<span className="text-red-500">*</span></span>
                                                            {errors.notes && (
                                                                <span className="text-red-500" >Please enter at least 10 characters.</span>
                                                            )}
                                                        </div>
                                                        <RichEditor
                                                            name="notes"
                                                            value={problem?.notes || ''}
                                                            options="Discussion"
                                                            {...register('notes', { required: 'Content should have more than 10 characters.', minLength: { value: 10, message: 'Content should have more than 10 characters.' } })}
                                                            onChange={onEditorContentChanged}
                                                            height="rich"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-16 text-[16px] font-medium">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Input 1 (Show on IDE) <span className="text-red-500">*</span></span>
                                                            {errors?.showInput1 && (
                                                                <span className="text-red-500">{errors?.showInput1.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="showInput1"
                                                            defaultValue={problem?.showInput1 || ''}
                                                            control={control}
                                                            rules={{ required: 'Input 1 (Show on IDE) is required' }}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    name="showInput1"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Input 1"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-2 text-[16px] font-medium">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Input 2 (Show on IDE) <span className="text-red-500">*</span></span>
                                                            {errors?.showInput2 && (
                                                                <span className="text-red-500">{errors?.showInput2.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="showInput2"
                                                            control={control}
                                                            defaultValue={problem?.showInput2 || ''}
                                                            rules={{ required: 'Input 2 (Show on IDE) is required' }}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    name="showInput2"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Input 2"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-2 mb-2 text-[16px] font-medium">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Input 3 (Show on IDE) <span className="text-red-500">*</span></span>
                                                            {errors?.showInput3 && (
                                                                <span className="text-red-500">{errors?.showInput3.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="showInput3"
                                                            control={control}
                                                            defaultValue={problem?.showInput3 || ''}
                                                            rules={{ required: 'Input 3 (Show on IDE) is required' }}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    name="showInput3"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Input 3"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </Accordion>
                                        </div>
                                        <div className='mt-6'>
                                            <Accordion title="Section - 2">
                                                <div className="flex flex-col gap-y-2 mb-6">
                                                    <div className='flex items-center gap-x-4'>
                                                        <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Time Limit<span className="text-red-500">*</span></span>
                                                        {errors?.cppTimeLimit && (
                                                            <span className="text-red-500">{errors?.cppTimeLimit.message}</span>
                                                        )}
                                                    </div>
                                                    <Controller
                                                        name="cppTimeLimit"
                                                        control={control}
                                                        defaultValue=''
                                                        rules={{ required: 'Time Limit is required' }}
                                                        render={({ field, fieldState }) => (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    name="cppTimeLimit"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Time Limit C++"
                                                                    {...field}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <Accordion title="C++">
                                                    <div className="flex flex-col gap-y-6">
                                                        <div className="flex flex-col gap-y-2">
                                                            <div className='flex items-center gap-x-4'>
                                                                <span className="text-gray-800 dark:text-zinc-300">Shown code on IDE <span className="text-red-500">*</span></span>
                                                                {errors?.publicCpp && (
                                                                    <span className="text-red-500">{errors?.publicCpp.message}</span>
                                                                )}
                                                            </div>
                                                            <Controller
                                                                name="publicCpp"
                                                                control={control}
                                                                defaultValue={problem?.publicCpp || ''}
                                                                rules={{ required: 'C++ Show on IDE is required' }}
                                                                render={({ field }) => (
                                                                    <textarea
                                                                        type="text"
                                                                        rows={10}
                                                                        name="publicCpp"
                                                                        className="form-input w-full text-gray-300"
                                                                        placeholder="Shown code on IDE"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col gap-y-2">
                                                            <div className='flex items-center gap-x-4'>
                                                                <span className="text-gray-800 dark:text-zinc-300">Remaning Code<span className="text-red-500">*</span></span>
                                                                {errors?.privateCpp && (
                                                                    <span className="text-red-500">{errors?.privateCpp.message}</span>
                                                                )}
                                                            </div>
                                                            <Controller
                                                                name="privateCpp"
                                                                defaultValue={problem?.privateCpp || ''}
                                                                control={control}
                                                                rules={{ required: 'C++ Remaning Code is required' }}
                                                                render={({ field }) => (
                                                                    <textarea
                                                                        type="text"
                                                                        rows={10}
                                                                        name="privateCpp"
                                                                        className="form-input w-full text-gray-300"
                                                                        placeholder="Remaning Code"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </Accordion>
                                                <div className='mt-5'>
                                                    <Accordion title="Java">
                                                        <div className="flex flex-col  gap-y-6">
                                                            <div className="flex flex-col gap-y-2">
                                                                <div className='flex items-center gap-x-4'>
                                                                    <span className="text-gray-800 dark:text-zinc-300">Shown code on IDE <span className="text-red-500">*</span></span>
                                                                    {errors?.publicJava && (
                                                                        <span className="text-red-500">{errors?.publicJava.message}</span>
                                                                    )}
                                                                </div>
                                                                <Controller
                                                                    name="publicJava"
                                                                    defaultValue={problem?.publicJava || ''}
                                                                    control={control}
                                                                    rules={{ required: 'Java Show on IDE is required' }}
                                                                    render={({ field }) => (
                                                                        <textarea
                                                                            type="text"
                                                                            rows={10}
                                                                            name="publicJava"
                                                                            className="form-input w-full text-gray-300"
                                                                            placeholder="Shown code on IDE"
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col gap-y-2">
                                                                <div className='flex items-center gap-x-4'>
                                                                    <span className="text-gray-800 dark:text-zinc-300">Remaning Code<span className="text-red-500">*</span></span>
                                                                    {errors?.privateJava && (
                                                                        <span className="text-red-500">{errors?.privateJava.message}</span>
                                                                    )}
                                                                </div>
                                                                <Controller
                                                                    name="privateJava"
                                                                    defaultValue={problem?.privateJava || ''}
                                                                    control={control}
                                                                    rules={{ required: 'Java Remaning Code is required' }}
                                                                    render={({ field }) => (
                                                                        <textarea
                                                                            type="text"
                                                                            rows={10}
                                                                            name="privateJava"
                                                                            className="form-input w-full text-gray-300"
                                                                            placeholder="Remaning Code"
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Accordion>
                                                </div>
                                                <div className='mt-5'>
                                                    <Accordion title="Python">
                                                        <div className="flex flex-col  gap-y-6">
                                                            <div className="flex flex-col gap-y-2">
                                                                <div className='flex items-center gap-x-4'>
                                                                    <span className="text-gray-800 dark:text-zinc-300">Shown code on IDE <span className="text-red-500">*</span></span>
                                                                    {errors?.publicPy && (
                                                                        <span className="text-red-500">{errors?.publicPy.message}</span>
                                                                    )}
                                                                </div>
                                                                <Controller
                                                                    name="publicPy"
                                                                    defaultValue={problem?.publicPy || ''}
                                                                    control={control}
                                                                    rules={{ required: 'Python Show on IDE is required' }}
                                                                    render={({ field }) => (
                                                                        <textarea
                                                                            type="text"
                                                                            rows={10}
                                                                            name="publicPy"
                                                                            className="form-input w-full text-gray-300"
                                                                            placeholder="Shown code on IDE"
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col gap-y-2">
                                                                <div className='flex items-center gap-x-4'>
                                                                    <span className="text-gray-800 dark:text-zinc-300">Remaning Code<span className="text-red-500">*</span></span>
                                                                    {errors?.privatePy && (
                                                                        <span className="text-red-500">{errors?.privatePy.message}</span>
                                                                    )}
                                                                </div>
                                                                <Controller
                                                                    name="privatePy"
                                                                    defaultValue={problem?.privatePy || ''}
                                                                    control={control}
                                                                    rules={{ required: 'Python Remaning Code is required' }}
                                                                    render={({ field }) => (
                                                                        <textarea
                                                                            type="text"
                                                                            rows={10}
                                                                            name="privatePy"
                                                                            className="form-input w-full text-gray-300"
                                                                            placeholder="Remaning Code"
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Accordion>
                                                </div>
                                                <div className='mt-5'>
                                                    <Accordion title="Javascript">
                                                        <div className="flex flex-col  gap-y-6">
                                                            <div className="flex flex-col gap-y-2">
                                                                <div className='flex items-center gap-x-4'>
                                                                    <span className="text-gray-800 dark:text-zinc-300">Shown code on IDE <span className="text-red-500">*</span></span>
                                                                    {errors?.publicJs && (
                                                                        <span className="text-red-500">{errors?.publicJs.message}</span>
                                                                    )}
                                                                </div>
                                                                <Controller
                                                                    name="publicJs"
                                                                    defaultValue={problem?.publicJs || ''}
                                                                    rules={{ required: 'Javascript Show on IDE is required' }}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <textarea
                                                                            type="text"
                                                                            rows={10}
                                                                            name="publicJs"
                                                                            className="form-input w-full text-gray-300"
                                                                            placeholder="Shown code on IDE"
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col gap-y-2">
                                                                <div className='flex items-center gap-x-4'>
                                                                    <span className="text-gray-800 dark:text-zinc-300">Remaning Code<span className="text-red-500">*</span></span>
                                                                    {errors?.privateJs && (
                                                                        <span className="text-red-500">{errors?.privateJs.message}</span>
                                                                    )}
                                                                </div>
                                                                <Controller
                                                                    name="privateJs"
                                                                    defaultValue={problem?.privateJs || ''}
                                                                    control={control}
                                                                    rules={{ required: 'Javascript Remaning Code is required' }}
                                                                    render={({ field }) => (
                                                                        <textarea
                                                                            type="text"
                                                                            rows={10}
                                                                            name="privateJs"
                                                                            className="form-input w-full text-gray-300"
                                                                            placeholder="Remaning Code"
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Accordion>
                                                </div>
                                                <div className='mt-8 flex flex-row  items-center gap-x-8 w-full'>
                                                    <div className="flex flex-col gap-y-2 w-full">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Input 1 <span className="text-red-500">*</span></span>
                                                            {errors?.realInput1 && (
                                                                <span className="text-red-500">{errors?.realInput1.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="realInput1"
                                                            defaultValue={problem?.realInput1 || ''}
                                                            control={control}
                                                            rules={{ required: 'Input 1 is required' }}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    type="text"
                                                                    rows={6}
                                                                    name="realInput1"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Input 1"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 w-full">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Output 1 <span className="text-red-500">*</span></span>
                                                            {errors?.realOutput1 && (
                                                                <span className="text-red-500">{errors?.realOutput1.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="realOutput1"
                                                            control={control}
                                                            defaultValue={problem?.realOutput1 || ''}
                                                            rules={{ required: 'Output 1 is required' }}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    type="text"
                                                                    rows={6}
                                                                    name="realOutput1"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Output 1"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-8 flex flex-row  items-center gap-x-8 w-full'>
                                                    <div className="flex flex-col gap-y-2 w-full">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Input 2 <span className="text-red-500">*</span></span>
                                                            {errors?.realInput2 && (
                                                                <span className="text-red-500">{errors?.realInput2.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="realInput2"
                                                            defaultValue={problem?.realInput2 || ''}
                                                            rules={{ required: 'Input 2 is required' }}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    type="text"
                                                                    rows={6}
                                                                    name="realInput2"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Input 2"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 w-full">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Output 2 <span className="text-red-500">*</span></span>
                                                            {errors?.realOutput2 && (
                                                                <span className="text-red-500">{errors?.realOutput2.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="realOutput2"
                                                            defaultValue={problem?.realOutput2 || ''}
                                                            control={control}
                                                            rules={{ required: 'Output 2 is required' }}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    type="text"
                                                                    rows={6}
                                                                    name="realOutput2"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Output 2"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-8 flex flex-row  items-center gap-x-8 w-full'>
                                                    <div className="flex flex-col gap-y-2 w-full">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Input 3 <span className="text-red-500">*</span></span>
                                                            {errors?.realInput3 && (
                                                                <span className="text-red-500">{errors?.realInput3.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="realInput3"
                                                            defaultValue={problem?.realInput3 || ''}
                                                            control={control}
                                                            rules={{ required: 'Input 3 is required' }}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    type="text"
                                                                    rows={6}
                                                                    name="realInput3"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Input 3"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 w-full">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300">Output 3 <span className="text-red-500">*</span></span>
                                                            {errors?.realOutput3 && (
                                                                <span className="text-red-500">{errors?.realOutput3.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="realOutput3"
                                                            control={control}
                                                            defaultValue={problem?.realOutput3 || ''}
                                                            rules={{ required: 'Output 3 is required' }}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    type="text"
                                                                    rows={6}
                                                                    name="realOutput3"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Output 3"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                {Array.from({ length: additionalFields }).map((_, index) => (
                                                    <div className='mt-8 flex flex-row w-full items-center gap-x-8' key={index}>
                                                        <div className="flex flex-col gap-y-2 w-full">
                                                            <div className='flex justify-between'>

                                                                <div className='flex items-center gap-x-4'>
                                                                    <span onClick={handleRemoveFields} className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">{`Input ${index + 4}`} <span className="text-red-500">*</span></span>
                                                                </div>
                                                                <button onClick={handleRemoveFields}>
                                                                    <BadgeX className='stroke-red-600' size={18} />
                                                                </button>
                                                            </div>
                                                            <Controller
                                                                name={`realInput${index + 4}`}
                                                                control={control}
                                                                rules={{ required: `Input ${index + 4} is required` }}
                                                                defaultValue={edgeCasesValueArray[index]?.input || ""}
                                                                render={({ field }) => (
                                                                    <textarea
                                                                        type="text"
                                                                        rows={6}
                                                                        name={`realInput${index + 4}`}
                                                                        className="form-input w-full text-gray-300"
                                                                        placeholder={`Input ${index + 4}`}
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col gap-y-2 w-full">
                                                            <div className='flex justify-between'>
                                                                <div className='flex items-center gap-x-4'>
                                                                    <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">{`Output ${index + 4}`} <span className="text-red-500">*</span></span>
                                                                    {/* {errors?.realInput3 && (
                                                                        <span className="text-red-500">{`Output ${index + 4} is required`}</span>
                                                                    )} */}
                                                                </div>
                                                                <button onClick={handleRemoveFields}>
                                                                    <BadgeX className='stroke-red-600' size={18} />
                                                                </button>
                                                            </div>

                                                            <Controller
                                                                name={`realOutput${index + 4}`}
                                                                control={control}
                                                                rules={{ required: `Output ${index + 4} is required` }}
                                                                defaultValue={edgeCasesValueArray[index]?.output || ""}
                                                                render={({ field }) => (
                                                                    <textarea
                                                                        type="text"
                                                                        rows={6}
                                                                        name={`realOutput${index + 4}`}
                                                                        className="form-input w-full text-gray-300"
                                                                        placeholder={`Output ${index + 4}`}
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='mt-8'>
                                                    <button type="button" onClick={handleAddFields} className='btn bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'>
                                                        <Plus size={18} className='stroke-zinc-600' />
                                                    </button>
                                                </div>
                                                <div className='mt-10 '>
                                                    <div className="flex flex-col gap-y-2 mt-2">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">TC Generator Language <span className="text-red-500">*</span></span>
                                                            {errors?.testCaseCode_lang && (
                                                                <span className="text-red-500">{errors?.testCaseCode_lang.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="testCaseCode_lang"
                                                            control={control}
                                                            defaultValue=''
                                                            render={({ field }) => (
                                                                <select
                                                                    {...field}
                                                                    className="form-select w-full text-gray-300"
                                                                >
                                                                    <option value="">Select TC Generator Language...</option>
                                                                    <option value="cpp">CPP</option>
                                                                    <option value="javascript">Javascript</option>
                                                                    <option value="java">Java</option>
                                                                    <option value="python">Python</option>
                                                                </select>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-6">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Test Case Generator Code <span className="text-red-500">*</span></span>
                                                            {errors?.testCaseCode && (
                                                                <span className="text-red-500">{errors?.testCaseCode.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="testCaseCode"
                                                            control={control}
                                                            rules={{ required: `Test Case Generator Code is required` }}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    type="text"
                                                                    name="testCaseCode"
                                                                    rows={8}
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Big Test Case Generator Code..."
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-6 '>
                                                    <div className="flex flex-col gap-y-2 mt-2">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Correct Code Language<span className="text-red-500">*</span></span>
                                                            {errors?.correctCodeLang && (
                                                                <span className="text-red-500">{errors?.correctCodeLang.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="correctCodeLang"
                                                            control={control}
                                                            defaultValue=''
                                                            render={({ field }) => (
                                                                <select
                                                                    {...field}
                                                                    className="form-select w-full text-gray-300"
                                                                >
                                                                    <option value="">Select Correct Code Language...</option>
                                                                    <option value="cpp">CPP</option>
                                                                    <option value="javascript">Javascript</option>
                                                                    <option value="java">Java</option>
                                                                    <option value="python">Python</option>
                                                                </select>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-4">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Correct Code Language <span className="text-red-500">*</span></span>
                                                            {errors?.correctCode && (
                                                                <span className="text-red-500">{errors?.correctCode.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="correctCode"
                                                            control={control}
                                                            rules={{ required: `Correct Code  is required` }}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    type="text"
                                                                    name="correctCode"
                                                                    rows={8}
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Correct Code..."
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </Accordion>
                                        </div>
                                        <div className='mt-6'>
                                            <Accordion title="Section - 3">
                                                <div className='mt-5 flex flex-col gap-y-4'>
                                                    <div className="flex flex-col gap-y-2">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Name<span className="text-red-500">*</span></span>
                                                            {errors?.author_name && (
                                                                <span className="text-red-500">{errors?.author_name.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="author_name"
                                                            defaultValue={problem?.author_name || ''}
                                                            control={control}
                                                            rules={{ required: `Name is required` }}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    name="author_name"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Name"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-y-2 mt-6">
                                                        <div className='flex items-center gap-x-4'>
                                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">LinkedIn Profile Link<span className="text-red-500">*</span></span>
                                                            {errors?.author_linkedin && (
                                                                <span className="text-red-500">{errors?.author_linkedin.message}</span>
                                                            )}
                                                        </div>
                                                        <Controller
                                                            name="author_linkedin"
                                                            defaultValue={problem?.author_linkedin || ''}
                                                            control={control}
                                                            rules={{ required: `LinkedIn Profile Link is required` }}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    name="author_linkedin"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="LinkedIn Profile Link"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </Accordion>
                                        </div>

                                        <div className='mt-8 flex flex-row  items-center gap-x-4'>
                                            <button className='btn text-[16px] font-medium bg-brand_50 border-brand_50 text-zinc-100' type='submit'>Save</button>
                                            <Link to={`/problem-setter/draft/${id}/preview`} target='_blank' className='btn text-[16px] font-medium' >Preview</Link>

                                        </div>
                                    </form>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default EditPublished