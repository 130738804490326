// import React, { useState } from 'react';

import { Classic } from "@theme-toggles/react";
import "@theme-toggles/react/css/Classic.css";
import { useState } from "react";
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useDarkMode from '../../hooks/useDarkMode';
import SearchModal from "../SearchModal/SearchModal";
import UserMenu from "../UserMenu/UserMenu";
// import { logout } from '../../../services/operations/authAPi';

function Header({
    sidebarOpen,
    setSidebarOpen
}) {

    const [darkTheme, setDarkTheme] = useDarkMode();

    const location = useLocation();
    const { pathname } = location;

    const { token } = useSelector((state) => state.auth)
    const { signinData } = useSelector((state) => state.auth)

    const [searchModalOpen, setSearchModalOpen] = useState(false)

    return (
        <header className="sticky bg-white dark:bg-dark top-0 border-b dark:border-lead500 border border-lead100  z-30">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 -mb-px">
                    <div className="flex">
                        <button
                            className="text-gray-500 hover:text-gray-600 lg:hidden"
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <rect x="4" y="5" width="16" height="2" />
                                <rect x="4" y="11" width="16" height="2" />
                                <rect x="4" y="17" width="16" height="2" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex items-center space-x-4">
                        <button
                            className={`w-10 h-10 flex items-center justify-center bg-gray-100 dark:bg-dark_50 hover:dark:bg-dark_40 border-2 border-lead100 dark:border-lead500  hover:bg-gray-200 transition duration-150 rounded-full ml-3 ${searchModalOpen && 'bg-gray-200'}`}
                            onClick={(e) => { e.stopPropagation(); setSearchModalOpen(true); }}
                            aria-controls="search-modal"
                        >
                            <span className="sr-only">Search</span>
                            <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path className="fill-current text-gray-500" d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                <path className="fill-current text-gray-400" d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                            </svg>
                        </button>
                        {pathname.includes('preview') ? (null) : (
                            <SearchModal id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} />
                        )}
                        <div className="ml-4 flex justify-center items-center p-1 rounded-lg border-brand_10 bg-[#FDEDEA] dark:bg-[#ee4c2b39] dark:border-[#ee4c2b88] border-2">
                            <Classic toggled={darkTheme} toggle={setDarkTheme} duration={750} />
                        </div>
                        {token ? (
                            <UserMenu
                                email={signinData?.email}
                                username={signinData?.username}
                                align='right'
                            />
                        ) : (
                            <Link to='/' className="btn-brand-2">
                                <span className="relative">Login</span>
                            </Link>
                        )}
                    </div>

                </div>
            </div>
        </header >
    );
}

export default Header;