import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from '../../../components/Header/Header'
import CoreTable from '../CoreTable';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllDBMS } from '../../../services/operations/blogs';

const DBMS = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const { allDBMS } = useSelector((state) => state.question);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getAllDBMS())
    }, [dispatch])

    const filtered = allDBMS.filter(topic =>
        topic['title']?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-2 sm:px-4 lg:px-6 py-6 w-full max-w-9xl mx-auto">
                        <div className="sm:flex sm:justify-between sm:items-center mb-8">
                            <div className="relative w-1/2 mb-6 mx-auto">
                                <label className="sr-only">Search</label>
                                <input
                                    className="w-full border rounded-lg focus:ring-transparent placeholder-gray-400 appearance-none py-2 pl-10 pr-4 dark:bg-dark_50 dark:placeholder:text-zinc-200 dark:text-zinc-200 border-slate-300 bg-light_10/20 placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 hover:border-slate-400 dark:hover:border-slate-500 bg-white"
                                    type="search"
                                    placeholder="Search Question..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button
                                    className="absolute inset-1 right-auto group  rounded-md"
                                    aria-label="Search"
                                >
                                    <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-600 ml-2 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div>
                            <CoreTable topics={filtered} page="DBMS_Sheet" cacheName="admin:question:allDBMS" />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default DBMS