import React from 'react'
import CoreTableItem from './CoreTableItem'

const CoreTable = ({ topics, page, cacheName }) => {
    return (
        <div className="  font-primary  relative">
            <div className=' bg-white dark:bg-dark border-2  dark:border-dark_40 rounded-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-r-2  dark:border-dark_40 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <p className="font-semibold flex items-start">Problem</p>
                                </th>
                                <th className="px-2 border-r-2  dark:border-dark_40 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <p className="font-semibold">Article</p>
                                </th>
                                <th className="px-2 border-r-2  dark:border-dark_40 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <p className="font-semibold">Youtube</p>
                                </th>
                                <th className="px-2 border-r-2  dark:border-dark_40 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <p className="font-semibold">Action</p>
                                </th>
                            </tr>
                        </thead>
                        {
                            topics?.map((data, index) => {
                                return (
                                    <CoreTableItem
                                        key={data.id}
                                        page={page}
                                        cacheName={cacheName}
                                        id={data.id}
                                        num={index + 1}
                                        title={data.title}
                                        yt_link={data.yt_link}
                                        article_link={data.article_link}
                                    />
                                )
                            })
                        }
                    </table>
                </div>
            </div>

        </div>
    )
}

export default CoreTable