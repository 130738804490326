import React from 'react'
import { useDispatch } from 'react-redux'
import { closeBug } from '../../../../services/operations/bugs';
const BugsTableItem = (props) => {


    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeBug(props?.id))
    }
    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.id}.
                    </div>
                </td>
                <td className="px-2 w-1/3 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.name}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium w-1/2 cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.message}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        {!props?.isClosed ? (
                            <button type="button" onClick={handleClose} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Close</button>
                        ) : (
                            <>
                            </>
                        )}
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default BugsTableItem