import { useDispatch } from 'react-redux'
import { apiConnector } from '../../services/apiConnector';
import { blogsEndpoints } from '../../services/api';
import toast from 'react-hot-toast';
import { getAllColleges } from '../../services/operations/blogs';

const { VERIFY_COLLEGE , DELETE_COLLEGE } = blogsEndpoints

const TopicTableItem = (props) => {

    const dispatch = useDispatch()

    const handleSave = async () => {
        try {
            const id = props?.id
            const college = props?.college
            const response = await apiConnector("PUT", VERIFY_COLLEGE, { id, college })

            if(response.data.success) {
                toast.success("College Verified")
                dispatch(getAllColleges())
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDelete = async () => {
        try {
            const id = props?.id
            const college = props?.college
            const response = await apiConnector("PUT", DELETE_COLLEGE, { id, college })

            if(response.data.success) {
                toast.success("College Deleted")
                dispatch(getAllColleges())
            }
        } catch (error) {
            console.log(error)
        }
    }

    const statusColor = (status) => {
        switch (status) {
            case 1:
                return 'dark:bg-[#0B2816] bg-green-200 px-4 py-1 text-green-600';
            default:
                return 'bg-red-200 dark:bg-[#470C08] px-4 py-1 text-red-500';
        }
    };
    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.num}.
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.college}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap flex justify-center items-center">
                    <div className={` font-medium rounded-full w-fit flex justify-center items-center ${statusColor(props.status)}`}>{props.status === 1 ? 'Verified' : 'Not Verifed'}</div>

                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    {props.status === 1 ? (
                        <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                            <button onClick={handleDelete} className='btn bg-red-500 text-white  border-gree-500'>Delete</button>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                            <button onClick={handleSave} className='btn bg-green-500 text-white  border-gree-500'>Save</button>
                        </div>
                    )}
                </td>
            </tr>
        </tbody>
    )
}

export default TopicTableItem