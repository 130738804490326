import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import { login } from '../../services/operations/auth';

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token } = useSelector((state) => state.auth)
    const { handleSubmit, control, formState: { errors } } = useForm({ mode: 'all' });

    useEffect(() => {
        if(token) {
            navigate('/admin')
        }
    }, [navigate, token])

    const isPasswordValid = (value) => {
        return value.length >= 6 || 'Password must be at least 6 characters';
    };
    const handleOnSubmit = (data) => {
        const { userName, password } = data;
        dispatch(login(userName, password, navigate))
    }

    return (
        <div className="font-primary dark:bg-dark flex flex-col min-h-screen overflow-hidden">
            <Navbar />
            <main className="flex-grow">
                <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pt-28 pb-12">
                            <div className="max-w-3xl mx-auto text-center pb-8 md:pb-10">
                                <h1 className="text-5xl dark:text-zinc-200 font-extrabold leading-tight tracking-tighter">Welcome back!</h1>
                            </div>

                            <div className="max-w-sm mx-auto">
                                <form onSubmit={handleSubmit(handleOnSubmit)}>
                                    {/* Email Field Validation */}
                                    <div className="flex flex-wrap -mx-3 mb-4">
                                        <div className="w-full px-3">
                                            <Controller
                                                name="userName"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        type="text"
                                                        name="userName"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder="Username"
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Username is required',
                                                }}
                                            />
                                            {errors.username && <p className="text-red-600 text-sm italic">{errors.username.message}</p>}
                                        </div>
                                    </div>

                                    {/* Password Field Validation */}
                                    <div className="flex flex-wrap -mx-3 mb-4">
                                        <div className="w-full px-3">
                                            <Controller
                                                name="password"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder="Password (at least 10 characters)"
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Password is required',
                                                    validate: {
                                                        isPasswordValid,
                                                    },
                                                }}
                                            />
                                            {errors.password && <p className="text-red-600 text-sm italic">{errors.password.message}</p>}
                                        </div>
                                    </div>


                                    <div className="flex flex-wrap -mx-3 mt-6">
                                        <div className="w-full px-3">
                                            <button type="submit" className="btn-brand">Sign in</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Login