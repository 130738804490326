import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteDraft } from '../../services/operations/problemSetterAPI'
import { ExternalLink, LinkedinIcon } from 'lucide-react'

const TableItem = (props) => {

    const dispatch = useDispatch()
    const { signinData } = useSelector((state) => (state.auth))

    const email = signinData?.email
    const handleDeleteDraft = () => {
        dispatch(deleteDraft(props?.id, email))

    }
    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.num}.
                    </div>
                </td>
                {props?.page === 'success' ? (
                    <>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.userName}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center p-2">
                                {props?.story_text}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <a href={props?.linkedIn_link} target='_blank' rel="noreferrer" className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                <LinkedinIcon className='stroke-blue-600' />

                            </a>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            {props?.image_link ? (
                                <a href={props?.image_link} target='_blank' rel="noreferrer" className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                    <ExternalLink className='stroke-orange-600'/>
                                </a>
                            ) : (
                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">

                                </div>
                            )}
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                {props?.company_name}
                            </div>
                        </td>
                    </>
                ) : (
                    <>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.name}
                            </div>
                        </td>

                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                {props?.date}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                {props.showDelete ? (
                                    <button onClick={handleDeleteDraft} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>
                                ) : (null)}
                                {props?.page === "Draft" && (
                                    <Link to={`/problem-setter/draft/${props?.id}/edit`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                                )}
                                {props?.page === "Published" && (
                                    <div>
                                        {signinData?.role === "Admin" ? (
                                            <Link to={`/published/${props?.id}/edit`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                                        ) : (
                                            <Link to={`/published/${props?.id}/preview`} target='_blank' className='btn bg-green-500 text-white  border-gree-500'>View</Link>
                                        )}
                                    </div>
                                )}
                                {props?.page === "Pending" && (
                                    <Link to={`/pending/${props?.id}/edit`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                                )}
                            </div>
                        </td>
                    </>
                )}

            </tr>
        </tbody>
    )
}

export default TableItem