import { Link } from "react-router-dom"
import Error404 from "../../components/Error404/Error404"
import Navbar from "../../components/Navbar/Navbar"
import { useSelector } from "react-redux"

const NotFound = () => {

    const { token } = useSelector((state) => state.auth)

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <Navbar />
            <main className="flex items-center flex-col justify-center mb-20" >
                <Error404 />
                <div className="mt-8">
                    <Link to={token ? '/admin' : '/'} className="box-border font-primary relative z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-brand rounded-md cursor-pointer group ring-offset-2 ring-1 ring-brand_50 ring-offset-[#f66c50] hover:ring-offset-[#ff6c4f] ease focus:outline-none">
                        <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="relative z-20 flex items-center text-sm">
                            Go to Home
                        </span>
                    </Link>
                </div>
            </main>
        </div >
    )
}
export default NotFound