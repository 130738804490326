import React from 'react';

function TablePagination({ itemsPerPage, indexOfFirstItem, indexOfLastItem, totalItems, currentPage, paginate }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="flex px-5 py-4 flex-col items-center sm:flex-row sm:items-center sm:justify-between">
            <nav className="sm:order-1">
                <div className="flex justify-center">
                    <div className="ml-3 first:ml-0">
                        {currentPage > 1 ? (
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                className="bg-[#f96345] rounded-md   border-gray-200 dark:border-zinc-800 hover:border-gray-300 text-white"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-left"><path d="m15 18-6-6 6-6" /></svg>
                            </button>
                        ) : (
                            <div className="bg-zinc-200 rounded-md  border-gray-200 dark:border-zinc-800 text-gray-300 dark:text-zinc-800 dark:bg-dark_50 cursor-not-allowed">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-left"><path d="m15 18-6-6 6-6" /></svg>
                            </div>
                        )}
                    </div>
                    <div className="ml-3 first:ml-0">
                        {currentPage < pageNumbers.length ? (
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                className="bg-[#f96345]  rounded-md   border-gray-200 dark:border-zinc-800 hover:border-gray-300  text-white"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg>
                            </button>
                        ) : (
                            <div className="bg-zinc-200 rounded-md  border-gray-200 dark:border-zinc-800 text-gray-300 dark:text-zinc-800 dark:bg-dark_50 cursor-not-allowed">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
            <div className="text-sm text-gray-500 text-center sm:text-left">
                <span className="font-medium text-gray-600">
                    {indexOfFirstItem + 1}
                </span>{' '}
                -{' '}
                <span className="font-medium text-gray-600">
                    {Math.min(indexOfLastItem, totalItems)}
                </span>{' '}
                of{' '}
                <span className="font-medium text-gray-600">{totalItems}</span>
            </div>
        </div>
    );
}

export default TablePagination;
