import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    drafts: [],
    pending: [],
    published: [],
    problem: {},
    loading: false,
}

const problemsSlice = createSlice({
    name: "problems",
    initialState: initialState,
    reducers: {
        setAllDrafts(state, value) {
            state.drafts = value.payload
        },
        setAllPublished(state, value) {
            state.published = value.payload
        },
        setAllPending(state, value) {
            state.pending = value.payload
        },
        setProblem(state, value) {
            state.problem = value.payload
        },
        setLoading(state, value) {
            state.loading = value.payload
        },
    },
})

export const { setAllPending, setLoading, setAllDrafts, setAllPublished, setProblem } = problemsSlice.actions

export default problemsSlice.reducer
