import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../components/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addBaner, editBanner, getAllBanners, removeBanner } from '../../services/operations/auth';

const AddBanner = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [bannerId, setBannerId] = useState(null);
    const { allBanners } = useSelector((state) => state.auth)
    const { handleSubmit, setValue, control, watch, formState: { errors } } = useForm({
        mode: 'all',
        defaultValues: {
            hasIcon: 0,
            hasTargetDate: 0,
            isActive: 0,
        },
    });

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllBanners())
    }, [dispatch])

    const hasIcon = watch('hasIcon'); // Watch for changes in hasIcon
    const hasTargetDate = watch('hasTargetDate'); // Watch for changes in hasTargetDate
    const handleAddBanner = (e) => {
        e.stopPropagation()
        setModalOpen(true);
        setIsEdit(false)
        setBannerId(null)
        setValue('heading', '');
        setValue('description', '');
        setValue('hasIcon', 0);
        setValue('icon', '');
        setValue('hasTargetDate', 0);
        setValue('targetDate', '');
        setValue('availNowRoute', '');
        setValue('isActive', 0);
    }
    const onSubmit = (data) => {
        if(isEdit) {
            const { hasIcon, hasTargetDate, isActive, heading, description, availNowRoute, icon, targetDate } = data
            dispatch(editBanner(heading, description, hasIcon, icon, hasTargetDate, targetDate, availNowRoute, isActive, setModalOpen, bannerId))
        }else {
            const { hasIcon, hasTargetDate, isActive, heading, description, availNowRoute, icon, targetDate } = data
            dispatch(addBaner(heading, description, hasIcon, icon, hasTargetDate, targetDate, availNowRoute, isActive, setModalOpen))
        }
    };

    const handleDelete = (e, id) => {
        dispatch(removeBanner(id))
    };
    const handleEdit = (e, heading, description, hasIcon, icon, hasTargetDate, targetDate, availNowRoute, isActive, id) => {
        setIsEdit(true);
        e.stopPropagation();
        setModalOpen(true);
        setBannerId(id)
        setValue('heading', heading);
        setValue('description', description);
        setValue('hasIcon', hasIcon);
        setValue('icon', icon);
        setValue('hasTargetDate', hasTargetDate);
        setValue('targetDate', targetDate);
        setValue('availNowRoute', availNowRoute);
        setValue('isActive', isActive);
    };
    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="flex flex-row justify-between items-center">

                            <div className="relative">
                            </div>
                            <button onClick={(e) => handleAddBanner(e)} className="px-6 py-2 rounded-lg text-white bg-brand hover:bg-brand_50 w-fit ">Add Banner</button>
                        </div>
                        <div className="font-primary  relative mt-10">
                            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                                <div className="overflow-x-auto">
                                    <table className="table-auto w-full  divide-y  divide-gray-200">
                                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                                            <tr>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">No.</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/3 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                                    <div className="font-semibold flex items-start">Heading</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">Desc</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">hasIcon</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">icon</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">hasTargetDate</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">targetDate</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">availNowRoute</div>
                                                </th>
                                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-center">isActive</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        {allBanners && allBanners?.length > 0 &&
                                            allBanners?.map((data, index) => {
                                                return (
                                                    <tbody className="text-[13px]">
                                                        <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                                                            <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                                    {data.id}.
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.heading}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.description}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.hasIcon}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.icon}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.hasTargetDate}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.targetDate}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.availNowRoute}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.isActive}
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                                                    <button onClick={(e) => handleDelete(e, data.id)} className='btn '>Delete</button>
                                                                </div>
                                                                <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                                                    <button onClick={(e) => handleEdit(e, data?.heading, data?.description, data?.hasIcon, data?.icon, data?.hasTargetDate, data?.targetDate, data?.availNowRoute, data?.isActive, data?.id)} className='btn  mt-2'>Edit</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Modal title={"Add Banner"} modalOpen={modalOpen} setModalOpen={setModalOpen}>

                            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-4 mt-4">
                                {/* Heading */}
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Heading<span className="text-red-500">*</span>
                                        </label>
                                        {errors?.heading && (
                                            <span className="text-red-500">{errors.heading.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="heading"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className="form-input w-full"
                                                placeholder="Enter heading"
                                            />
                                        )}
                                    />
                                </div>

                                {/* Description */}
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Description<span className="text-red-500">*</span>
                                        </label>
                                        {errors?.description && (
                                            <span className="text-red-500">{errors.description.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className="form-input w-full"
                                                placeholder="Enter description"
                                            />
                                        )}
                                    />
                                </div>

                                {/* Has Icon */}
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Has Icon<span className="text-red-500">*</span>
                                        </label>
                                        {errors?.hasIcon && (
                                            <span className="text-red-500">{errors.hasIcon.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="hasIcon"
                                        control={control}
                                        defaultValue={0}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className="form-select w-full"
                                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                            >
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>
                                            </select>
                                        )}
                                    />
                                </div>

                                {/* Icon - Conditionally Rendered */}
                                {hasIcon === 1 && (
                                    <div className="flex flex-col gap-y-2">
                                        <div className="flex items-center gap-x-4">
                                            <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                                Icon
                                            </label>
                                            {errors?.icon && (
                                                <span className="text-red-500">{errors.icon.message}</span>
                                            )}
                                        </div>
                                        <Controller
                                            name="icon"
                                            control={control}
                                            render={({ field }) => (
                                                <textarea
                                                    {...field}
                                                    className="form-textarea w-full"
                                                    placeholder="Enter icon"
                                                />
                                            )}
                                        />
                                    </div>
                                )}

                                {/* Has Target Date */}
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Has Target Date
                                        </label>
                                        {errors?.hasTargetDate && (
                                            <span className="text-red-500">{errors.hasTargetDate.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="hasTargetDate"
                                        control={control}
                                        defaultValue={0}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className="form-select w-full"
                                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                            >
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>
                                            </select>
                                        )}
                                    />
                                </div>

                                {/* Target Date - Conditionally Rendered */}
                                {hasTargetDate === 1 && (
                                    <div className="flex flex-col gap-y-2">
                                        <div className="flex items-center gap-x-4">
                                            <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                                Target Date
                                            </label>
                                            {errors?.targetDate && (
                                                <span className="text-red-500">{errors.targetDate.message}</span>
                                            )}
                                        </div>
                                        <Controller
                                            name="targetDate"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="form-input w-full"
                                                    placeholder="Enter target date"
                                                />
                                            )}
                                        />
                                    </div>
                                )}

                                {/* Avail Now Route */}
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Avail Now Route
                                        </label>
                                        {errors?.availNowRoute && (
                                            <span className="text-red-500">{errors.availNowRoute.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="availNowRoute"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className="form-input w-full"
                                                placeholder="Enter avail now route"
                                            />
                                        )}
                                    />
                                </div>

                                {/* Is Active */}
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex items-center gap-x-4">
                                        <label className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Is Active
                                        </label>
                                        {errors?.isActive && (
                                            <span className="text-red-500">{errors.isActive.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="isActive"
                                        control={control}
                                        defaultValue={0}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className="form-select w-full"
                                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                            >
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>
                                            </select>
                                        )}
                                    />
                                </div>

                                {/* Submit Button */}
                                <div className="mt-4">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddBanner