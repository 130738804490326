import React, { useState } from 'react'
import Header from '../../../components/Header/Header'
import Sidebar from '../../../components/Sidebar/Sidebar'
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { setUser } from '../../../services/operations/auth';

const AddUser = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({ mode: 'all' });

    const isEmailValid = (value) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailPattern.test(value) || 'Invalid email address';
    };

    const isPasswordValid = (value) => {
        return value.length >= 6 || 'Password must be at least 6 characters';
    };

    const handleOnSubmit = (data) => {
        const { userName, email, password, role } = data;
        dispatch(setUser(userName, email, password, role))
        setValue('userName', '');
        setValue('email', '');
        setValue('password', '');
        setValue('role', '');
    }

    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mb-8">
                            <div className="flex-grow font-primary">
                                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                                    <div className="pt-28 pb-12">
                                        <div className="max-w-3xl mx-auto text-center pb-8 md:pb-10">
                                            <h1 className="text-5xl dark:text-zinc-200 font-extrabold leading-tight tracking-tighter">Add User</h1>
                                        </div>
                                        <div className="max-w-sm mx-auto">
                                            <form onSubmit={handleSubmit(handleOnSubmit)}>
                                                <div className="flex flex-wrap -mx-3 mb-4">
                                                    <div className="w-full px-3">
                                                        <Controller
                                                            name="userName"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    name="userName"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Username"
                                                                    {...field}
                                                                />
                                                            )}
                                                            rules={{
                                                                required: 'Username is required',
                                                            }}
                                                        />
                                                        {errors.username && <p className="text-red-600 text-sm italic">{errors.username.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap -mx-3 mb-4">
                                                    <div className="w-full px-3">
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Email Address"
                                                                    {...field}
                                                                />
                                                            )}
                                                            rules={{
                                                                required: 'Email is required',
                                                                validate: {
                                                                    isEmailValid,
                                                                },
                                                            }}
                                                        />
                                                        {errors.email && <p className="text-red-600 text-sm italic">{errors.email.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap -mx-3 mb-4">
                                                    <div className="w-full px-3">
                                                        <Controller
                                                            name="password"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="password"
                                                                    name="password"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Password (at least 10 characters)"
                                                                    {...field}
                                                                />
                                                            )}
                                                            rules={{
                                                                required: 'Password is required',
                                                                validate: {
                                                                    isPasswordValid,
                                                                },
                                                            }}
                                                        />
                                                        {errors.password && <p className="text-red-600 text-sm italic">{errors.password.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap -mx-3 mb-4">
                                                    <div className="w-full px-3">
                                                        <Controller
                                                            name="role"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    name="role"
                                                                    className="form-input w-full text-gray-300"
                                                                    placeholder="Intern or Admin"
                                                                    {...field}
                                                                />
                                                            )}
                                                            rules={{
                                                                required: 'Role is required',
                                                            }}
                                                        />
                                                        {errors.role && <p className="text-red-600 text-sm italic">{errors.role.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap -mx-3 mt-6">
                                                    <div className="w-full px-3">
                                                        <button type="submit" className="btn-brand">Add User</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddUser