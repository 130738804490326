import useDarkMode from "../../hooks/useDarkMode";

function DarkModeToggle() {
    const [darkTheme, setDarkTheme] = useDarkMode(); // Assuming useDarkMode returns the state and setter

    const toggleTheme = () => {
        setDarkTheme(prevTheme => !prevTheme);
    };

    return (
        <button className={`text-[2.4px] relative h-[42px] w-[80px] transition-all duration-500 ease-in-out mx-auto my-0 rounded-full bg-[#1f1f1f] cursor-pointer border-[1.5px] ${darkTheme ? ' border-zinc-800' : 'bg-[#fef4cd] border-[#FACC15]'} `} onClick={toggleTheme}>
            <div className={`moon ${darkTheme ? '' : 'sun'}`}></div>
        </button>
    );
}

export default DarkModeToggle;
