/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import Table from "../../../components/Table/Table";
import { useSelector } from "react-redux";

const Drafts = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { drafts } = useSelector((state) => (state.problems))

    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mb-8">
                            <div className="flex-grow font-primary">
                                <section className=''>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row justify-between items-center">

                                            <div className="relative">
                                            </div>
                                            <Link to="/problem-setter/draft/add-problem" className="px-6 py-2 rounded-lg text-white bg-brand hover:bg-brand_50 w-fit ">Add Problem</Link>
                                        </div>
                                        <div className="mt-6">
                                            {drafts?.length > 0 ? (
                                                <Table showDelete={true} page="Draft" topics={drafts} />
                                            ) : (
                                                <div className="flex justify-center items-center">
                                                    <h1 className="text-xl font-medium dark:text-zinc-200">There are no articles in this category.</h1>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Drafts