import toast from "react-hot-toast"
import { blogsEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import { setAllDrafts, setAllPending, setAllPublished, setBlog, setBlogCategories, setBlogTopics } from "../../slices/blogSlice"
import { setAllCN, setAllColleges, setAllDBMS, setAllOS, setAllQuestions, setQuestionTopics } from "../../slices/questionSlice"
import { mergeAllData } from "../../utils/Utils"


const { GET_ARTICLE, GET_ALL_DRAFTS, GET_ALL_PENDING, GET_ALL_PUBLISHED, GET_ALL_CATEGORY, GET_ALL_TOPICS, NEW_BLOG, REVERT_BLOG, PUBLISH_BLOG, SUBMIT_BLOG, DELETE_ARTICLE, SAVE_BLOG, ADD_CATEGORY, GET_PUBLISHED_ARTICLE, UPDATE_PUBLISHED_BLOG, GET_ALL_QUESTION_TOPICS, GET_ALL_QUESTIONS, GET_ALL_COLLEGES, GET_ALL_CN, GET_ALL_DBMS, GET_ALL_OS } = blogsEndpoints

export function getArticle(aid) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ARTICLE + `?aid=${aid}`)
            dispatch(setBlog(response.data))

            // console.log(response.data)
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }
}

export function getPublishedArticle(aid) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_PUBLISHED_ARTICLE + `?aid=${aid}`)
            dispatch(setBlog(response.data))

            console.log(response.data)
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }
}

export function deleteArticle(aid) {
    return async (dispatch) => {
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await apiConnector("POST", DELETE_ARTICLE, { aid })

            toast.success("Blog Deleted Successfully")
            dispatch(getBlogsDrafts())
            // console.log(response.data)
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }
}
export function getBlogsDrafts(email, navigate) {
    return async (dispatch) => {
        try {
            // Record the start time just before making the API call
            // const startTime = performance.now();

            const response = await apiConnector("GET", GET_ALL_DRAFTS);

            // Calculate the time taken and convert to seconds
            // const endTime = performance.now();
            // const timeTaken = (endTime - startTime) / 1000; // Convert milliseconds to seconds
            // console.log("Time taken to fetch data:", timeTaken, "seconds");

            dispatch(setAllDrafts(response.data));
            // console.log(response.data)
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }
}


export function getBlogsPendings(email, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_PENDING)

            dispatch(setAllPending(response.data))
            // console.log(response.data)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getBlogsPublished(email, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_PUBLISHED)


            dispatch(setAllPublished(response.data))
            // console.log(response.data)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getBlogCategories(email, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_CATEGORY)


            dispatch(setBlogCategories(response.data))
            // console.log(response.data)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getBlogTopics(email, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_TOPICS)

            const formattedOptions = response.data.map(item => ({
                value: item["topic-id"],
                label: item["topic-title"],
            }));
            dispatch(setBlogTopics(formattedOptions))
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getQuestionTopics() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_QUESTION_TOPICS)

            const formattedOptions = response.data.map(item => ({
                value: item["id"],
                label: item["topic_name"],
            }));
            dispatch(setQuestionTopics(formattedOptions))
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function getAllQuestion() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_QUESTIONS)

            dispatch(setAllQuestions(response.data))
        } catch (error) {

            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function getAllCN() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_CN)

            dispatch(setAllCN(mergeAllData(response.data)))
        } catch (error) {

            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function getAllDBMS() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_DBMS)

            // console.log(response.data)
            dispatch(setAllDBMS(mergeAllData(response.data)))
        } catch (error) {

            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}

export function getAllOS() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_OS)

            dispatch(setAllOS(mergeAllData(response.data)))
        } catch (error) {

            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function getAllColleges() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_COLLEGES)

            dispatch(setAllColleges(response.data))
        } catch (error) {

            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}


export function addBlog(navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", NEW_BLOG)

            // console.log(response.data.aid)
            if (response.data.aid) {
                navigate(`/write/draft/${response.data.aid}/edit`)
            } else {
                toast.error("Connot create new blog")
            }

        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function submitBlog(aid, title, category_id, topics, slug, content, video, comments, navigate, role) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", SUBMIT_BLOG, { aid, title, category_id, topics, slug, content, video, comments })

            if (response.data.aid) {
                toast.success("Submitted Review")
                dispatch(getBlogsDrafts())

                if (role === 'Admin') {
                    navigate(`/write/pending`)
                } else {
                    navigate(`/write/draft`)
                }
            } else {
                toast.error("Connot save blog")
            }


        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function saveBlog(aid, title, category_id, topics, slug, content, video, comments, navigate, page) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", SAVE_BLOG, { aid, title, category_id, topics, slug, content, video, comments })

            if (response.data.aid) {
                // navigate(`/write/${page}`)
                dispatch(getBlogsDrafts())
                toast.success("Blog Saved")
            } else {
                toast.error("Connot save blog")
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function revertBlog(aid, title, category_id, topics, slug, content, video, comments, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", REVERT_BLOG, { aid, title, category_id, topics, slug, content, video, comments })

            if (response.data.aid) {
                toast.success("Blog Reverted")
                navigate(`/write/draft`)
                dispatch(getBlogsDrafts())
            } else {
                toast.error("Connot revert blog")
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function publishBlog(aid, title, category_id, topics, slug, content, video, comments, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", PUBLISH_BLOG, { aid, title, category_id, topics, slug, content, video, comments })

            if (response.data.aid) {
                toast.success("Blog Published")
                navigate(`/write/published`)
            } else {
                toast.error("Connot Publish blog")
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function updatePublishedBlog(aid, title, category_id, topics, slug, content, video, comments, navigate) {
    return async (dispatch) => {
        try {
            const toastId = toast.loading("Loading...");
            const response = await apiConnector("POST", UPDATE_PUBLISHED_BLOG, { aid, title, category_id, topics, slug, content, video, comments })

            console.log(response.data)
            // if (response.data.aid) {
            toast.success("Changes Saved Successfully")
            // navigate(`/write/published`)
            toast.dismiss(toastId);
            // } else {
            // }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function addNewTag(newTag, desc, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", ADD_CATEGORY, { newTag, desc })

            navigate('/write/draft')
            toast.success(response.data.message)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
