/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import { Controller, useForm } from 'react-hook-form';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addNewTag } from '../../../services/operations/blogs';

const AddTag = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        defaultValues: '',
    });

    const { signinData } = useSelector((state) => (state.auth))
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleOnSubmit = (data) => {
        const { newTag, desc } = data;
        dispatch(addNewTag(newTag, desc, navigate))
    }

    useEffect(() => {
        if (signinData?.role === 'Intern') {
            navigate('/write/draft')
        }
    }, [navigate, signinData?.role])

    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mb-8">
                            <form onSubmit={handleSubmit(handleOnSubmit)}>
                                <div className="flex flex-col gap-y-2">
                                    <div className='flex items-center gap-x-4'>
                                        <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Enter the label for new option<span className="text-red-500">*</span></span>
                                        {errors?.newTag && (
                                            <span className="text-red-500">{errors?.newTag.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="newTag"
                                        control={control}
                                        rules={{ required: 'Tag is required' }}
                                        render={({ field, fieldState }) => (
                                            <div>
                                                <input
                                                    type="text"
                                                    name="newTag"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Tag"
                                                    {...field}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="flex flex-col gap-y-2 mt-4">
                                    <div className='flex items-center gap-x-4'>
                                        <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">Description<span className="text-red-500">*</span></span>
                                        {errors?.desc && (
                                            <span className="text-red-500">{errors?.desc.message}</span>
                                        )}
                                    </div>
                                    <Controller
                                        name="desc"
                                        control={control}
                                        rules={{ required: 'Desc is required' }}
                                        render={({ field, fieldState }) => (
                                            <div>
                                                <input
                                                    type="text"
                                                    name="desc"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Desc"
                                                    {...field}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className='mt-4 flex flex-row  items-center gap-x-4'>
                                    <button className='btn text-[16px] font-medium bg-brand_50 border-brand_50 text-zinc-100' type='submit'>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddTag