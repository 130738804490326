import { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Table from "../../../components/Table/Table";
import { useSelector } from "react-redux";

const Pending = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { pending } = useSelector((state) => (state.problems))


    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mb-8">
                            <div className="flex-grow font-primary">
                                <section className=''>
                                    <div className="flex flex-col">

                                        <div className="mt-6">
                                            {pending?.length > 0 ? (
                                                <Table showDelete={false} page="Pending" topics={pending} />
                                            ) : (
                                                <div className="flex justify-center items-center">
                                                    <h1 className="text-xl font-medium dark:text-zinc-200">No pending articles to review.</h1>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Pending