import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import './article.css'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getPublishedArticle } from '../../../services/operations/blogs';
import { isObjectEmpty } from '../../../utils/Utils';
import Prism from "prismjs";
import "prismjs/components/prism-java";
import "prismjs/components/prism-c";
import "prismjs/components/prism-cpp";
import "prismjs/components/prism-python";

const PublishedPreview = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const params = useParams()
    const dispatch = useDispatch()
    const { blog, blogCategories } = useSelector((state) => state.blogs)

    useEffect(() => {
        Prism.highlightAll();
    }, [blog?.content]);
    useEffect(() => {
        dispatch(getPublishedArticle(params.id))
    }, [dispatch, params.id])

    function getCategoryTag(categoryId) {
        const category = blogCategories?.find(category => category?.category_id === categoryId);
        return category ? category.category_tag : 'Uncategorized'; // Return 'Uncategorized' if category is not found
    }

    useEffect(() => {
        const codeSections = document.querySelectorAll("details.code-section");

        codeSections.forEach((detailsElement) => {
            detailsElement.addEventListener("toggle", () => {
                const codeTabs = detailsElement.querySelector(".code-tabs");
                if (detailsElement.open) {
                    // console.log("Code section is open");
                    // Handle open state
                    if (codeTabs) {
                        const existingCopyButton = codeTabs.querySelector('.copy-button');
                        if (!existingCopyButton) {
                            const copyButton = document.createElement('button');
                            copyButton.className = 'copy-button';
                            copyButton.textContent = 'Copy';
                            codeTabs.appendChild(copyButton);
                        }
                    }
                } else {
                    // console.log("Code section is closed");
                    // Handle closed state
                    if (codeTabs) {
                        const copyButton = codeTabs.querySelector('.copy-button');
                        if (copyButton) {
                            copyButton.remove();
                        }
                    }
                }
            });
        });
    });

    useEffect(() => {
        const codeSections = document.querySelectorAll(".code-section");
        // Get last selected language from localStorage or set default value
        let lastSelectedLanguage =
            localStorage.getItem("lastSelectedLanguage") || "cpp";

        codeSections.forEach((section, sectionIndex) => {
            const codeTabs = section.querySelectorAll(".code-tab");
            const codeBlocks = section.querySelectorAll(".code-block");
            const copyBtn = section.querySelector(".copy-btn");
            const cpb = section.querySelector(".copy-button")

            if (copyBtn) copyBtn?.remove()


            codeTabs.forEach(
                (tab) => {
                    tab.addEventListener("click", () => {
                        codeTabs.forEach((t) => {
                            t.classList.remove("dsa_article_code_active");
                        });
                        tab.classList.add("dsa_article_code_active");

                        const lang = tab.dataset.lang;
                        const activeBlock = section.querySelector(
                            `.code-block[data-lang="${lang}"]`
                        );
                        codeBlocks.forEach((block) => {
                            if (block === activeBlock) {
                                block.classList.add("dsa_article_code_active");
                            } else {
                                block.classList.remove("dsa_article_code_active");
                            }
                        });

                        // Update last selected language for all sections and store in localStorage
                        lastSelectedLanguage = lang;
                        localStorage.setItem("lastSelectedLanguage", lang);
                        codeSections.forEach((otherSection, otherIndex) => {
                            if (otherIndex !== sectionIndex) {
                                const otherTabs = otherSection.querySelectorAll(".code-tab");
                                otherTabs.forEach((otherTab) => {
                                    if (otherTab.dataset.lang === lang) {
                                        otherTab.classList.add("dsa_article_code_active");
                                    } else {
                                        otherTab.classList.remove("dsa_article_code_active");
                                    }
                                });
                                const otherActiveBlock = otherSection.querySelector(
                                    `.code-block[data-lang="${lang}"]`
                                );
                                const otherCodeBlocks =
                                    otherSection.querySelectorAll(".code-block");
                                otherCodeBlocks.forEach((block) => {
                                    if (block === otherActiveBlock) {
                                        block.classList.add("dsa_article_code_active");
                                    } else {
                                        block.classList.remove("dsa_article_code_active");
                                    }
                                });
                            }
                        });
                    });
                },
                [blog?.content]
            );

            if (cpb) {
                // Handle copy button click event
                cpb.addEventListener("click", () => {
                    const activeBlock = section.querySelector(
                        ".code-block.dsa_article_code_active code"
                    );
                    if (activeBlock) navigator.clipboard.writeText(activeBlock.innerText);
                    // console.log(activeBlock.innerText)
                    // cpb.classList.add("dsa_article_code_active");
                    // setTimeout(() => {
                    //     cpb.classList.remove("dsa_article_code_active");
                    // }, 1000);
                });
            }
        });


        // Set initial state of the tabs
        codeSections.forEach((section) => {
            const codeTabs = section.querySelectorAll(".code-tab");
            const codeBlocks = section.querySelectorAll(".code-block");

            codeTabs.forEach((tab) => {
                if (tab.dataset.lang === lastSelectedLanguage) {
                    tab.classList.add("dsa_article_code_active");
                } else {
                    tab.classList.remove("dsa_article_code_active");
                }
            });

            codeBlocks.forEach((block) => {
                if (block.dataset.lang === lastSelectedLanguage) {
                    block.classList.add("dsa_article_code_active");
                } else {
                    block.classList.remove("dsa_article_code_active");
                }
            });
        });
    });
    useEffect(() => {

        const handleClick = (e) => {
            e.preventDefault();
            const target = e.target.hash;
            const element = document.querySelector(target);
            const bruteForceAlgorithmDetails = document.querySelector('#brute-force-approach details').querySelector('details');
            const betterAlgorithmDetails = document.querySelector('#better-approach details').querySelector('details');
            const optimalAlgorithmDetails = document.querySelector('#optimal-approach details').querySelector('details');
            const details = document.querySelectorAll('details');
            const videoDetails = document.querySelector("#dsa_article_video_explanation").querySelector('details')

            for (let j = 0; j < details.length; j++) {
                if (details[j].querySelector(`a[href="${target}"]`) === null) {
                    details[j].removeAttribute('open');
                    details[j].querySelector('summary').classList.remove('active');
                }
            }

            element?.querySelector('details')?.setAttribute('open', true);
            bruteForceAlgorithmDetails.setAttribute('open', true);
            betterAlgorithmDetails.setAttribute('open', true);
            videoDetails.setAttribute('open', true);
            optimalAlgorithmDetails.setAttribute('open', true);

            const links = document.querySelectorAll('a[href^="#"]');
            for (let j = 0; j < links.length; j++) {
                if (links[j].hash === target) {
                    links[j].classList.add('actively');
                    setTimeout(() => {
                        links[j].classList.remove('actively');
                    }, 3000);
                } else {
                    links[j].classList.remove('actively');
                }
            }


        };

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => link.addEventListener('click', handleClick));

        return () => {
            links.forEach(link => link.removeEventListener('click', handleClick));
        };
    });


    return (
        <div className="flex bg-[#fafafa] dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {blog && !isObjectEmpty(blog) ? (
                            <div className='flex flex-col'>
                                <div className='flex flex-col gap-y-3 items-start'>
                                    {/* <span className='text-sm text-gray-500 font-medium '>{blog?.publishedOn}</span>
                                    <div className='w-1 h-1 rounded-full bg-gray-400'></div> */}
                                    <span className='text-sm text-gray-500 font-medium'>Category : {getCategoryTag(blog?.category_id)}</span>
                                    <span className='text-sm text-gray-500 font-medium'>Slug: {blog?.slug}</span>
                                    <span className='text-sm text-gray-500 font-medium'>Topics: {blog?.topics?.label}</span>
                                </div>
                                <div className='flex sm:flex-row flex-col justify-between items-center'>
                                    <p className='text-[36px] font-bold'>{blog?.title}</p>
                                </div>

                                <div className="text-lg mt-4 font-medium text-zinc-800 dark:text-zinc-200 article" dangerouslySetInnerHTML={{ __html: blog?.content }} />



                                {blog?.video && (
                                    <div className='mt-4 flex justify-center items-center'>
                                        <iframe class="lazy-loaded" loading="lazy" width="810" height="456" data-lazy-type="iframe" data-src={`https://www.youtube.com/embed/${blog?.video}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="" src={`https://www.youtube.com/embed/${blog?.video}`}></iframe><noscript><iframe loading="lazy" width="810" height="456" src={`https://www.youtube.com/embed/${blog?.video}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe></noscript>
                                    </div>
                                )}
                            </div>
                        ) : (null)}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default PublishedPreview