import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { apiConnector } from '../../services/apiConnector';
import { blogsEndpoints } from '../../services/api';

const { UPDATE_CORE_SHEET } = blogsEndpoints

const CoreTableItem = (props) => {

    const [articleLink, setArticleLink] = useState(props?.article_link || null);
    const [title, setTitle] = useState(props?.title || null);
    const [ytLink, setYTLink] = useState(props?.yt_link || null);

    const handleInput = (event, setter) => {
        setter(event.target.value);
    };

    const handleSave = async () => {
        try {
            const toastId = toast.loading("Loading...");
            const response = await apiConnector("PUT", UPDATE_CORE_SHEET + `${props?.id}`, {
                article_link: articleLink,
                yt_link: ytLink,
                sheetName: props?.page,
                cacheName: props?.cacheName
            })

            if (response.data.success) {
                toast.success("Updated successfully")
            } else {
                toast.error("Failed update")
            }
            toast.dismiss(toastId);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <tbody className="text-[13px]" key={props?.title}>
            <tr className='border-t-2 border-b-2 last:border-b-0  dark:border-dark_40'>
                <td className="px-2 border-l-2  dark:border-dark_40 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        <input className='form-input w-10/12' disabled type="text" value={title} onChange={(e) => handleInput(e, setTitle)} />
                    </div>
                </td>
                <td className="px-2 border-l-2  dark:border-dark_40 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        <input className='form-input w-10/12' type="text" value={articleLink} onChange={(e) => handleInput(e, setArticleLink)} />
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        <input className='form-input w-10/12' type="text" value={ytLink} onChange={(e) => handleInput(e, setYTLink)} />
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handleSave} className='btn bg-green-500 text-white  border-gree-500'>Save</button>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default CoreTableItem