import React from 'react';
// import TopicTablePagination from "./TopicTablePagination"
import TopicTableItem from './TopicTableItem';

function TopicTable({ topics }) {
    return (
        <div className="  font-primary  relative">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                {/* <th className="px-2 border-2 border-lead100 dark:border-lead500  py-2">
                                    <div className="font-semibold text-center">No.</div>
                                </th> */}
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/5 py-2">
                                    <div className="font-semibold flex items-start">Title</div>
                                </th>
                                <th className="px-2 w-1/6 border-2 border-lead100 dark:border-lead500 py-2">
                                    <div className="font-semibold text-center">Post Link</div>
                                </th>
                                <th className="px-2 w-1/6 border-2 border-lead100 dark:border-lead500 py-2">
                                    <div className="font-semibold text-center">YT Link</div>
                                </th>
                                <th className="px-2 w-1/6 border-2 border-lead100 dark:border-lead500 py-2">
                                    <div className="font-semibold text-center">CS Link</div>
                                </th>
                                <th className="px-2 w-1/6 border-2 border-lead100 dark:border-lead500 py-2">
                                    <div className="font-semibold text-center">LC Link</div>
                                </th>
                                <th className="px-2 w-1/5  border-2 border-lead100 dark:border-lead500 py-2">
                                    <div className="font-semibold text-center">Difficulty</div>
                                </th>
                                <th className="px-2 w-1/5 border-2 border-lead100 dark:border-lead500 py-2">
                                    <div className="font-semibold text-center">Topics</div>
                                </th>
                                <th className="px-2 w-1/5 border-2 border-lead100 dark:border-lead500 py-2">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {
                           topics &&  topics?.map((data, index) => {
                                return (
                                    <TopicTableItem
                                        id={data?.id}
                                        num={index + 1}
                                        title={data?.title}
                                        difficulty={data?.difficulty}
                                        topics={data?.ques_topic}
                                        cs_link={data?.cs_link}
                                        lc_link={data?.lc_link}
                                        yt_link={data?.yt_link}
                                        post_link={data?.post_link}
                                    />
                                )
                            })
                        }
                    </table>
                </div>
            </div>
            {/* <div className="dark:bg-dark_50  border-2 border-t-0 border-lead100 dark:border-lead500">
                <TopicTablePagination
                    itemsPerPage={itemsPerPage}
                    totalItems={topics?.length}
                    currentPage={currentPage}
                    paginate={paginate}
                    indexOfFirstItem={indexOfFirstItem}
                    indexOfLastItem={indexOfLastItem}
                />
            </div> */}
        </div>
    );
}

export default TopicTable;
