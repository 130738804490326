import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from '../../../components/Header/Header'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProblemDetails } from '../../../services/operations/problemSetterAPI';
import CodeEditor from '../../../components/CodeEditor/CodeEditor';
import { mergeArrays } from '../../../utils/Utils';

const Preview = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [edgeCasesValueArray, setEdgeCasesValueArray] = useState([])

    const dispatch = useDispatch()
    
    const { id } = useParams();
    const { problem } = useSelector((state) => (state.problems))

    useEffect(() => {
        dispatch(getProblemDetails(id))
    }, [dispatch, id])

    useEffect(() => {

        const jsonString = problem?.edgeCases;

        if (jsonString) {
            try {
                const parsedArray = JSON.parse(jsonString);
                if (Array.isArray(parsedArray)) {
                    setEdgeCasesValueArray(parsedArray)
                } 
            } catch (error) {
                // console.error('Error parsing JSON:', error);
            }
        } 
    }, [problem?.edgeCases])

    const data = [
        {
            input: problem?.realInput1,
            output: problem?.realOutput1,
        },
        {
            input: problem?.realInput2,
            output: problem?.realOutput2,
        },
        {
            input: problem?.realInput3,
            output: problem?.realOutput3,
        },
    ]

    const io = mergeArrays(data, edgeCasesValueArray)

    return (
        <div className="flex bg-[#F1F5F9] dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="bg-white dark:bg-transparent mb-8">
                            <div className="flex-grow font-primary">
                                <section className='p-10'>
                                    {problem && (
                                        <div className='flex flex-col items-start'>
                                            <h1 className='text-2xl font-bold text-[#212121] dark:text-zinc-200 '>{problem?.problem_name}</h1>
                                            <p className='mt-6 text-[#212121] dark:text-zinc-200' dangerouslySetInnerHTML={{ __html: problem?.problem_statement }} />

                                            <div className='w-full mt-3 flex flex-col gap-y-2 items-start p-2 bg-[#f8f8f8] border border-[#E9E9E9] dark:bg-[#191919] dark:border-zinc-700  rounded-lg'>
                                                <span className='text-[#212121] dark:text-zinc-200 font-semibold'>Example 1</span>
                                                <div className='text-[#595959] dark:text-zinc-400 font-medium py-2 px-4' dangerouslySetInnerHTML={{ __html: problem?.example1 }} />
                                            </div>
                                            <div className='w-full mt-3 flex flex-col gap-y-2 items-start p-2 bg-[#f8f8f8] border border-[#E9E9E9] dark:bg-[#191919] dark:border-zinc-700 rounded-lg'>
                                                <span className='text-[#212121] dark:text-zinc-200 font-semibold'>Example 2</span>
                                                <div className='text-[#595959] dark:text-zinc-400 font-medium py-2 px-4' dangerouslySetInnerHTML={{ __html: problem?.example2 }} />
                                            </div>
                                            <div className='w-full mt-3 flex flex-col gap-y-2 items-start p-2 bg-[#f8f8f8] border border-[#E9E9E9] dark:bg-[#191919] dark:border-zinc-700 rounded-lg'>
                                                <span className='text-[#212121] dark:text-zinc-200 font-semibold'>Example 3</span>
                                                <div className='text-[#595959] dark:text-zinc-400 font-medium py-2 px-4' dangerouslySetInnerHTML={{ __html: problem?.example3 }} />
                                            </div>

                                            <div className='mt-6 flex flex-col gap-y-2'>
                                                <span className='text-[#212121] dark:text-zinc-200 font-semibold text-lg'>Constraints:</span>
                                                <div className='' dangerouslySetInnerHTML={{ __html: problem?.constraints }} />
                                            </div>
                                            <div className='mt-6 flex flex-col gap-y-2'>
                                                <span className='text-[#212121] dark:text-zinc-200  font-semibold text-lg'>Note:</span>
                                                <div className='text-[#212121] dark:text-zinc-200' dangerouslySetInnerHTML={{ __html: problem?.notes }} />
                                            </div>

                                            <div className='mt-10'>
                                                <CodeEditor
                                                    language={problem?.correctCodeLang}
                                                    code={problem?.correctCode}
                                                    io={io}
                                                />
                                            </div>
                                            
                                        </div>
                                    )}
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Preview