import BugsTableItem from './BugsTableItem';

function BugsTable({ topics }) {


    return (
        <div className="  font-primary  relative">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold flex items-start">Reported By</div>
                                </th>
                                <th className="px-2 border-2 max-w-1/2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Message</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            topics?.map((data, index) => {
                                return (
                                    <BugsTableItem
                                        id={data?.id}
                                        name={data?.name}
                                        message={data?.message}
                                        isClosed={data?.isClosed}
                                    />
                                )
                            })
                        }
                    </table>

                </div>
            </div>
        </div>
    );
}

export default BugsTable;
